export interface ISubscriptionPlan {
  id?: number;
  description?: string | null;
  price?: number | null;
  featuresIncluded?: string | null;
  stripePlanId?: string | null;
  active?: boolean | null;
  name?: string | null;
}

export const defaultValue: Readonly<ISubscriptionPlan> = {
  active: false,
};
