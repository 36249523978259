import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Label } from 'reactstrap';
import { Translate, ValidatedField } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { CheckIcon } from '../../shared/icon-svg/icon';

export const ThankYouModal = ({ handleModal, email }) => {
  return (
    <Modal className="thank-u-modal" isOpen toggle={handleModal}>
      <ModalHeader className="border-0 text-center" toggle={handleModal}>
        <CheckIcon />
        <Label>We've added you to our waiting list.</Label>
      </ModalHeader>
      <ModalBody>
        <p>We'll let you know when Knolli is ready.</p>
      </ModalBody>
      {/* <ModalFooter className="bg-light">
        <Label>Registered Email</Label>
        <ValidatedField id="email" name="email" type="text" value={email} />
      </ModalFooter> */}
    </Modal>
  );
};

export default ThankYouModal;
