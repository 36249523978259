import KnolliAi from './modules/aichat/AiChat';
import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
// import Loadable from 'react-loadable';

import LoginRedirect from '../../../app/modules/login/login-redirect';
import Logout from './modules/login/logout';
import Home from './modules/home/home';
import PrivateRoute from '../../../app/shared/auth/private-route';
import ErrorBoundaryRoutes from '../../../app/shared/error/error-boundary-routes';
// import PageNotFound from '../../../app/shared/error/page-not-found';
import PageNotFound from '../../../themes/default/app/error/page-not-found';
import { AUTHORITIES } from '../../../app/config/constants';
import AdminDash from './modules/admin/admin';
import PricingPlans from './modules/subscription-plans/Subscription-plans';
import { hideHeader, showHeader } from './shared/layout/header/header.reducer';
import { useAppDispatch } from '../../../app/config/store';

// const loading = <div>loading ...</div>;

// const Admin = Loadable({
//   loader: () => import(/* webpackChunkName: "administration" */ '../../../app/modules/administration'),
//   loading: () => loading,
// });
const AppRoutes = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(hideHeader());
    return () => {
      dispatch(showHeader());
    };
  }, [dispatch]);

  return (
    <div className="view-routes">
      <ErrorBoundaryRoutes>
        <Route
          path="/copilot/:id"
          element={
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.USER]}>
              <KnolliAi />
            </PrivateRoute>
          }
        />
        <Route
          path="admin/*"
          element={
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.USER]}>
              <AdminDash />
            </PrivateRoute>
          }
        />

        <Route path="/" element={<Home />} />
        <Route path="logout" element={<Logout />} />
        <Route path="oauth2/authorization/oidc" element={<LoginRedirect />} />
        {/* <Route
          path="*"
          element={
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.USER]}>
              <EntitiesRoutes />
            </PrivateRoute>
          }
        /> */}
        <Route
          path="/plans"
          element={
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.USER]}>
              <PricingPlans />
            </PrivateRoute>
          }
        />

        <Route path="*" element={<PageNotFound />} />
      </ErrorBoundaryRoutes>
    </div>
  );
};

export default AppRoutes;
