import { IAiAssitant } from 'app/shared/model/ai-assitant.model';
import { SourceDocumentType } from 'app/shared/model/enumerations/source-document-type.model';

export interface ISourceDocument {
  id?: number;
  fileName?: string | null;
  title?: string | null;
  documentType?: keyof typeof SourceDocumentType | null;
  content?: string | null;
  sourceLocation?: string | null;
  fileLocation?: string | null;
  fileUploadId?: number | null;
  aiAssitant?: IAiAssitant | null;
}

export const defaultValue: Readonly<ISourceDocument> = {};
