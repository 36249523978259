import { IAiAssitant } from 'app/shared/model/ai-assitant.model';
import { ISourceDocument } from 'app/shared/model/source-document.model';

export interface ISourceDocumentTextSegment {
  id?: number;
  fileName?: string | null;
  title?: string | null;
  content?: string;
  documentId?: number | null;
  index?: number;
  aiAssitant?: IAiAssitant | null;
  sourceDocument?: ISourceDocument | null;
}

export const defaultValue: Readonly<ISourceDocumentTextSegment> = {};
