import dayjs from 'dayjs';
import { IUser } from 'app/shared/model/user.model';
import { IKnolliAIUserProfileField } from 'app/shared/model/knolli-ai-user-profile-field.model';
import { AiAssistantType } from 'app/shared/model/enumerations/ai-assistant-type.model';

export interface IAiAssitant {
  id?: number;
  aiAssistantType?: keyof typeof AiAssistantType | null;
  userSystemMessage?: string | null;
  qdrantCollectionName?: string | null;
  createdDate?: dayjs.Dayjs | null;
  updatedDate?: dayjs.Dayjs | null;
  deletedDate?: dayjs.Dayjs | null;
  name?: string | null;
  user?: IUser | null;
  knolliAIUserProfileFields?: IKnolliAIUserProfileField[] | null;
}

export const defaultValue: Readonly<IAiAssitant> = {};
