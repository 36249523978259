import React, { useEffect, useState } from 'react';
import { useParams, useLocation, Link, useNavigate } from 'react-router-dom';
import { Row, Col, Button, ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText, Badge, Progress, Label } from 'reactstrap';
import { useAppDispatch, useAppSelector } from '../../../../../app/config/store';
import { overridePaginationStateWithQueryParams } from '../../../../../app/shared/util/entity-utils';
import { ITEMS_PER_PAGE } from '../../../../../app/shared/util/pagination.constants';
import { UsersIcon, WordsIcon, ItemsIcon } from '../../shared/icon-svg/icon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleUp, faPlus } from '@fortawesome/free-solid-svg-icons';

interface Props {
  cardCols?: string;
}
import { getPaginationState, ValidatedField } from 'react-jhipster';
import { getSession } from '../../../../../app/shared/reducers/authentication';
import { getProfile } from '../../../../../app/shared/reducers/application-profile';
import Sidebar from '../sidebar/sidebar';
import { hideHeader, showHeader } from '../../shared/layout/header/header.reducer';
import { fetchStatistics } from '../../entities/ai-chat/ai-chat.reducer';
import { getEntities as getAiAssistants } from '../../entities/ai-assitant/ai-assitant.reducer';
import { DatePicker } from 'antd';
import { QueriesPerDayGraph } from './graph';
import { amplitudeAnalytics, segmentAnalytics, analyticsTrack } from '../../shared/services/analytics';

export const AppDashboard = (props: Props) => {
  const userPromptFullText = useAppSelector(state => state.chatDashboard.userPromptFullText);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isSidebarCollapsed, setSidebarCollapsed] = useState(false);

  useEffect(() => {
    dispatch(getSession());
    dispatch(getProfile());
  }, [dispatch]);

  const { RangePicker } = DatePicker;

  const params = useParams<'id'>().id;
  const [userConversation, setUserConversation] = useState('');
  const [userConversationAI, setUserConversationAI] = useState<any>();
  const [userConversationId, setUserConversationId] = useState(null);
  const showTemplates = useAppSelector(state => state.chatDashboard.showTemplates);
  const [loading, setLoading] = useState();
  const [timeoutLoading, setTimeoutLoading] = useState(true);
  const [showLoading, setShowLoading] = useState(true);
  const userOldConversationId = useAppSelector(state => state.chatDashboard.userOldConversationId);
  const [generatingChatGptConversationId, setGeneratingChatGptConversationId] = useState('');
  const newConversation = useAppSelector(state => state.aIChat.entity);
  const [userNameInital, setUserNameInital] = useState('');
  var dataList = useAppSelector(state => state.aIChat.entities);
  const account = useAppSelector(state => state.authentication.account);
  const pageLocation = useLocation();
  const [error, setError] = useState('');
  const toggleMenu = () => setMenuOpen(!menuOpen);
  const [menuOpen, setMenuOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [userSelectedConversationId, setUserSelectedConversationId] = useState(null);
  const [isOpenFavourites, setIsOpenFavourites] = useState(false);
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  const userList = useAppSelector(state => state.aIChat.users);
  const [activeButton, setActiveButton] = useState('today');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [assistant, setAssistant] = useState();
  const aiAssitantList = useAppSelector(state => state.aiAssitant.entities);

  const totalUsers = useAppSelector(state => state.aIChat.totalUsers);
  const totalFiles = useAppSelector(state => state.fileUpload.totalItems);
  const totalPrompts = useAppSelector(state => state.userPrompt.totalItems);
  const topUsers = useAppSelector(state => state.aIChat.topUsers);
  const totalActiveUsers = useAppSelector(state => state.aIChat.statistics.data?.totalActiveUsers);
  const statisticData = useAppSelector(state => state.aIChat.statistics?.data);
  const [showCalendar, setShowCalendar] = useState(false);
  const [loaderdata, setLoaderD] = useState(true);
  const userId = useAppSelector(state => state.authentication.account.id);
  const [showGraph, setShowGraph] = useState(false);
  const loader = useAppSelector(state => state.aiAssitant.loading);

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getPaginationState(pageLocation, ITEMS_PER_PAGE, 'id'), pageLocation.search),
  );

  const configUserChars = account => {
    if (account?.firstName != null && account.firstName != undefined && account.firstName != '') {
      setUserNameInital(account.firstName.charAt(0));
    } else {
      setUserNameInital(account?.email.charAt(0));
    }
  };

  useEffect(() => {
    setLoading(loader);
  }, [loader]);

  useEffect(() => {
    const today = new Date().toISOString().split('T')[0];
    setStartDate(today);
    setEndDate(today);
    if (assistant) {
      dispatch(fetchStatistics({ statistics: 'ALL', startDate: today, endDate: today, aiAssistantId: assistant }));
    }
  }, []);

  const handleDateRangeClick = (days, isGraph = true, buttonKey: string) => {
    const endDate = new Date();
    const startDate = new Date();
    startDate.setDate(endDate.getDate() - days);

    const formattedStartDate = startDate.toISOString().split('T')[0]; // yyyy-mm-dd
    const formattedEndDate = endDate.toISOString().split('T')[0]; // yyyy-mm-dd

    setStartDate(formattedStartDate);
    setEndDate(formattedEndDate);
    setActiveButton(buttonKey);

    // Dispatch statistics fetch based on date range
    if (assistant) {
      dispatch(fetchStatistics({ statistics: 'ALL', startDate: formattedStartDate, endDate: formattedEndDate, aiAssistantId: assistant }));
    }
    setShowGraph(isGraph);
  };

  const [chatTitleLoaded, setChatTitleLoaded] = useState({
    chatId: null,
    isTitleLoaded: false,
  });

  useEffect(() => {}, [params]);

  // useEffect(() => {
  //   // Run code only for new conversations
  //   let newChatId = newConversation.id;
  //   if (newConversation.title == null) {
  //     setUserConversationId(newChatId);
  //     handleUpdateChatTitle(newChatId, false);
  //     setShowTemplates(true);
  //     dispatch(
  //       getEntities({
  //         page: paginationState.activePage - 1,
  //         size: paginationState.itemsPerPage,
  //         sort: `${paginationState.sort},${paginationState.order}`,
  //       }),
  //     );
  //   }
  // }, [newConversation]);

  useEffect(() => {
    if (aiAssitantList?.length > 0) {
      setAssistant(aiAssitantList[0].id);
    }
  }, [aiAssitantList]);

  useEffect(() => {
    if (dataList && dataList.length > 0) {
      let theChat = dataList.find(x => x.user?.id == account?.id);
    } else {
      setTimeout(() => {
        setTimeoutLoading(false);
      }, 1000);
    }
  }, [dataList]);

  useEffect(() => {
    localStorage.setItem('context', 'all');
    configUserChars(account);
    dispatch(getAiAssistants({}));
    // dispatch(getUsers({}));
    // dispatch(getFiles({}));
    // dispatch(getPrompts({}));
  }, []);

  // const handleUpdateChatTitle = (chatId, isTitleLoaded) => {
  //   setChatTitleLoaded({
  //     ...chatTitleLoaded,
  //     isTitleLoaded: isTitleLoaded,
  //     chatId: chatId,
  //   });
  // };

  useEffect(() => {
    if (account && account.id) {
      if (localStorage.getItem('loggedin') != 'true') {
        segmentAnalytics.identify(account.id, {
          firstName: account.firstName,
          lastName: account.lastName,
          email: account.email,
        });

        amplitudeAnalytics.setUserId(account.email);
        const identifyEvent = new amplitudeAnalytics.Identify();
        identifyEvent.set('firstName', account.firstName);
        identifyEvent.set('lastName', account.lastName);
        amplitudeAnalytics.identify(identifyEvent);

        analyticsTrack('User Logged in');

        localStorage.setItem('loggedin', 'true');
      }
      // dispatch(
      //   getEntities({
      //     page: paginationState.activePage - 1,
      //     size: paginationState.itemsPerPage,
      //     sort: `${paginationState.sort},${paginationState.order}`,
      //   }),
      // );
    }
  }, [account]);

  // const handleUserSelectedConversation = async (id, oldConversation) => {
  //   dispatch(setUserOldConversationId(id));

  //   if (id == generatingChatGptConversationId) {
  //     dispatch(setShowTemplates(false));
  //     setUserConversation(oldConversation + userPromptFullText + (userConversationAI ? userConversationAI : ''));
  //     setShowLoading(true);
  //   } else {
  //     if (oldConversation == '') {
  //       dispatch(setShowTemplates(true));
  //     }
  //     setUserConversation(oldConversation);
  //     setShowLoading(false);
  //   }
  // };

  // const onClickStartNewChatPlusButton = async () => {
  //   setUserConversation('');
  //   setUserSelectedConversationId(null);
  //   setShowTemplates(true);
  //   await dispatch(createEntity({}));
  // };

  // const [dropdownOpen, setDropdownOpen] = useState(false);

  // const toggle = () => setDropdownOpen(prevState => !prevState);

  useEffect(() => {
    dispatch(hideHeader()); // Hide header when this component is mounted
    return () => {
      dispatch(showHeader()); // Show header again when leaving this route
    };
  }, [dispatch]);

  useEffect(() => {
    if (assistant && startDate && endDate) {
      dispatch(
        fetchStatistics({
          statistics: 'ALL',
          startDate: startDate,
          endDate: endDate,
          aiAssistantId: assistant,
        }),
      );
    }
  }, [assistant]);

  const handleCustomDateRangeChange = (dates, dateStrings) => {
    if (dates) {
      setStartDate(dateStrings[0]); // Start date
      setEndDate(dateStrings[1]); // End date

      // Dispatch action to fetch data
      dispatch(
        fetchStatistics({
          statistics: 'ALL',
          startDate: dateStrings[0],
          endDate: dateStrings[1],
          aiAssistantId: assistant,
        }),
      );
      setShowGraph(true); // Show graph for the custom date range
    } else {
      setShowGraph(false); // Hide graph if no date is selected
    }
  };

  const handleCustomClick = () => {
    setShowCalendar(!showCalendar); // Toggle calendar visibility
  };

  const addAssistant = () => {
    analyticsTrack('Adding New AI Assistant CTA');
    navigate('/admin/manage/new');
  };

  const formatNumber = value => {
    return new Intl.NumberFormat().format(value);
  };

  return (
    <div className="dashboard-app-p py-3 overflow-hidden">
      <Sidebar />
      {loading == false && aiAssitantList.length === 0 && (
        <div className={'is_open p-3 d-flex flex-column containerMessage'}>
          <div className="kn-welcome-text d-flex flex-column flex-wrap gap-3">
            <div className="borderBox">
              <Label className="justify-content-center h2" style={{ marginBottom: '0' }}>
                Get Started
              </Label>
              <p className="mt-2">Create the AI Assistant to view statistics</p>
            </div>
            <Button className="btn-theme d-flex gap-2 align-items-center" onClick={addAssistant}>
              <FontAwesomeIcon icon={faPlus} className="text-white" />
              <span>Add a Knolli</span>
            </Button>
          </div>
        </div>
      )}
      <div className={'is_open p-3 d-flex flex-column'}>
        {/* Loader shown if loading is true */}
        {loading ? (
          <div className="app-loading" style={{ height: '500px', width: '300px', marginInline: 'auto' }}>
            <div className="conductor_loader-index"></div>
          </div>
        ) : (
          // Actual content rendered after data is loaded
          aiAssitantList &&
          aiAssitantList.length > 0 && (
            <div>
              <div className="buttons-wrapper d-flex flex-wrap gap-3">
                <ValidatedField
                  id="ai-chat-aiAssitant"
                  name="aiAssitant"
                  data-cy="aiAssitant"
                  type="select"
                  value={assistant}
                  className="assistantDropdown"
                  onChange={e => setAssistant(e.target.value)}
                >
                  <option value="" key="0" />
                  {aiAssitantList
                    ? aiAssitantList.map(otherEntity => (
                        <option value={otherEntity.id} key={otherEntity.id}>
                          {otherEntity.qdrantCollectionName}
                        </option>
                      ))
                    : null}
                </ValidatedField>
                <Button
                  outline
                  color="theme-clr"
                  className={`btn ${activeButton === 'today' ? 'active' : ''}`}
                  onClick={() => handleDateRangeClick(0, false, 'today')}
                >
                  Today
                </Button>

                <Button outline color="theme-clr" className="btn" onClick={() => handleDateRangeClick(1, false, 'yesterday')}>
                  Yesterday
                </Button>
                <Button outline color="theme-clr" className="btn" onClick={() => handleDateRangeClick(30, true, 'Last 30 days')}>
                  Last 30 days
                </Button>
                <Button outline color="theme-clr" className="btn" onClick={() => handleDateRangeClick(90, true, 'Last 90 days')}>
                  Last 90 days
                </Button>
                <Button outline color="theme-clr" className="btn" onClick={() => handleDateRangeClick(365, true, 'Last 365 days')}>
                  Last 365 days
                </Button>
                <Button outline color="theme-clr" className="btn" onClick={handleCustomClick}>
                  Custom
                </Button>
                {showCalendar && <RangePicker onChange={handleCustomDateRangeChange} />}
              </div>

              {/* Available to withdraw starts */}
              <ListGroup horizontal className="mt-3 available-withdraw-sec flex-wrap">
                <ListGroupItem className="border-0 flex-grow-1">
                  <ListGroupItemHeading className="d-flex gap-2 flex-wrap justify-content-between">
                    Total active users
                    {/* <Badge pill color="theme-badge">
                      <FontAwesomeIcon icon={faAngleUp} /> 10.0%
                    </Badge> */}
                  </ListGroupItemHeading>
                  <ListGroupItemText className="count" tag={'h3'}>
                    {formatNumber(totalActiveUsers)}
                    {/* Avg. 3 min 42 sec per user */}
                  </ListGroupItemText>
                </ListGroupItem>
                <ListGroupItem className="border-0 flex-grow-1">
                  <ListGroupItemHeading className="d-flex gap-2 flex-wrap justify-content-between">
                    Total queries
                    {/* <Badge pill color="success-badge">
                      <FontAwesomeIcon icon={faAngleUp} /> 3.2%
                    </Badge> */}
                  </ListGroupItemHeading>
                  <ListGroupItemText className="count" tag={'h3'}>
                    {formatNumber(statisticData?.totalQueries)}
                    {/* 0.85 queries per user */}
                  </ListGroupItemText>
                </ListGroupItem>
              </ListGroup>

              {/* Total Queries Section starts */}
              <Row className="mt-1 g-4">
                <Col xs="12" md="7" lg="8" xl="9">
                  <div className="total-queries-sec">
                    <h4 className="section-title">Total queries this billing period</h4>
                    <div className="d-flex gap-5 mt-4">
                      <div className="count-vertical d-flex flex-column gap-4">
                        <div className="d-flex flex-column">
                          <h3 className="display-4 fw-bold">{formatNumber(statisticData?.queriesUsed)}</h3>
                          queries used
                        </div>
                        <div className="d-flex flex-column">
                          <h3 className="display-4 fw-bold">{formatNumber(statisticData?.queriesRemaining)}</h3>
                          queries remaining
                        </div>
                      </div>
                      <div className="graph flex-grow-1">
                        {activeButton == 'today' || activeButton == 'yesterday' ? (
                          <p className="text-end">Queries per hour</p>
                        ) : (
                          <p className="text-end">Queries per day</p>
                        )}
                        <div className="graph-container">
                          <QueriesPerDayGraph data={statisticData} activeTab={activeButton} />
                        </div>
                      </div>
                    </div>
                    <div className="d-flex gap-4 mt-4 flex-wrap">
                      <div className="stat-card">
                        <div className="icon-title d-flex gap-3 align-items-center justify-content-start">
                          <div className="icon">
                            <UsersIcon />
                          </div>
                          <span className="title m-0">Users</span>
                        </div>
                        <div className="value">{formatNumber(statisticData?.numberOfUsers)}</div>
                        <div className="progress-bar">
                          <Progress value={40} />
                        </div>
                      </div>

                      <div className="stat-card">
                        <div className="icon-title d-flex gap-3 align-items-center justify-content-start">
                          <div className="icon">
                            <WordsIcon />
                          </div>
                          <span className="title m-0">Tokens stored</span>
                        </div>
                        <div className="value">{formatNumber(statisticData?.numberOfTokens)}</div>
                        <div className="progress-bar">
                          <Progress value={55} />
                        </div>
                      </div>

                      <div className="stat-card">
                        <div className="icon-title d-flex gap-3 align-items-center justify-content-start">
                          <div className="icon">
                            <ItemsIcon />
                          </div>
                          <span className="title m-0">Items stored</span>
                        </div>
                        <div className="value">{formatNumber(statisticData?.numberOfItems)}</div>
                        <div className="progress-bar">
                          <Progress value={60} />
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>

                <Col xs="12" md="5" lg="4" xl="3">
                  <ListGroup className="user-list-view">
                    <h4 className="section-title">Top users by number of queries</h4>
                    {statisticData?.topUsers?.map(user => (
                      <ListGroupItem
                        key={user.userId}
                        className="border-0 px-0 my-1 d-flex gap-2 align-items-center justify-content-between"
                        href="#"
                        tag="a"
                      >
                        <img src="content/images/user--1.png" alt="" className="avatar" />
                        <div className="flex-grow-1">
                          <div className="user-info d-flex flex-row flex-row justify-content-between flex-grow-1">
                            <span className="user-name">
                              {user.firstName} {user.lastName}
                            </span>
                            <span className="query-count">{formatNumber(user.numberOfQueries)} queries</span>
                          </div>
                        </div>
                      </ListGroupItem>
                    ))}
                  </ListGroup>
                </Col>
              </Row>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default AppDashboard;
