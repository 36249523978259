import React, { useEffect, useState } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Label,
  Accordion,
  AccordionItem,
  AccordionHeader,
  AccordionBody,
  FormGroup,
  Input,
  Col,
  Row,
} from 'reactstrap';
import { SendIcon } from '../../shared/icon-svg/icon';
import { getPaginationState, ValidatedField } from 'react-jhipster';
import { knolliSuggestions } from '../../entities/ai-assitant/ai-assitant.reducer';
import { useAppDispatch, useAppSelector } from '../../../../../app/config/store';
import { analyticsTrack } from '../../shared/services/analytics';
import { createEntity } from '../../entities/ai-assitant/ai-assitant.reducer';
import { updateEntity as createProfileFields } from '../../entities/knolli-ai-user-profile-field/knolli-ai-user-profile-field.reducer';
import { getAiAssistants } from '../../entities/ai-assitant/ai-assitant.reducer';
import { overridePaginationStateWithQueryParams } from '../../../../../app/shared/util/entity-utils';
import { useLocation } from 'react-router';
import { ITEMS_PER_PAGE } from '../../../../../app/shared/util/pagination.constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const WizardModal = ({ handleWizard }) => {
  const dispatch = useAppDispatch();
  const pageLocation = useLocation();
  const [prompt, setPrompt] = useState('');
  const [suggestions, setSuggestions] = useState<any>();
  const [loading, setLoading] = useState<boolean>();
  const [step, setStep] = useState(1);
  const [open, setOpen] = useState('0');
  const [openFields, setOpenFields] = useState('0');
  const [name, setName] = useState('');
  const [selectedDocument, setSelectedDocument] = useState([]);
  const [selectedProfileFields, setSelectedProfileFields] = useState([]);
  const [userProfileFields, setUserProfileFields] = useState([]);
  const [field, setField] = useState('');
  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getPaginationState(pageLocation, ITEMS_PER_PAGE, 'id'), pageLocation.search),
  );

  const account = useAppSelector(state => state.authentication.account);

  const toggle = id => {
    if (open === id) {
      setOpen('');
    } else {
      setOpen(id);
    }
  };

  const toggleProfileFields = id => {
    if (openFields === id) {
      setOpenFields('');
    } else {
      setOpenFields(id);
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    const { payload } = await dispatch(knolliSuggestions(prompt));
    // console.log("payload", payload.data);
    setSuggestions(payload.data.data);
    setUserProfileFields(payload?.data?.data?.userProfileFieldSuggestions);
    setLoading(false);
    setStep(2);
  };

  const handleDocumentCheckbox = (e, doc) => {
    let isChecked = e.target.checked;
    if (isChecked) {
      setSelectedDocument(prevDocs => {
        return [...prevDocs, doc];
      });
    } else {
      let tempArr = selectedDocument.filter(obj => obj.type != doc.type);
      setSelectedDocument(tempArr);
    }
  };

  const handleProfileFieldsCheckbox = (e, fields) => {
    let isChecked = e.target.checked;
    if (isChecked) {
      setSelectedProfileFields(prevFields => {
        return [...prevFields, fields];
      });
    } else {
      let tempArr = selectedProfileFields.filter(obj => obj.name != fields.name);
      setSelectedProfileFields(tempArr);
    }
  };

  const handleSave = async () => {
    let fields = [];
    selectedProfileFields?.length > 0 &&
      selectedProfileFields.map(item => fields.push({ name: item.name, description: item.description, type: item.type }));
    const entityAssistant = {
      qdrantCollectionName: name.trim(),
      aiAssistantType: 'COPILOT',
      userSystemMessage: prompt,
      user: account,
    };
    analyticsTrack('Created AI Assisnant', { assitant: { name: entityAssistant.qdrantCollectionName } });
    const { payload } = await dispatch(createEntity(entityAssistant));
    const entity = {
      aiAssistantId: payload?.data?.id,
      fields: fields,
    };
    await dispatch(createProfileFields(entity));
    dispatch(
      getAiAssistants({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
      }),
    );
    handleWizard();
  };

  const handleAddFields = () => {
    if (field != '') {
      setUserProfileFields(prevFields => {
        return [...prevFields, { name: field, description: 'Added by user' }];
      });
      setSelectedProfileFields(prevFields => {
        return [...prevFields, { name: field, description: 'Added by user' }];
      });
      setField('');
    }
  };

  return (
    <Modal className="wizard-modal" isOpen toggle={handleWizard}>
      {loading && (
        <div className="app-loading" style={{ height: '200px', width: '200px', marginInline: 'auto' }}>
          <img src="../../content/images/knolli-Loader.gif" alt="Loading" height={200} width={200} />
        </div>
      )}
      {!loading && step == 1 && (
        <>
          <ModalHeader className="border-0 text-center" toggle={handleWizard}>
            <h3 className="fw-bold">What will your Knolli do?</h3>
            <p>Please enter your prompt.</p>
          </ModalHeader>
          <ModalBody>
            {/* <Label style={{fontWeight: '400', color: 'grey'}}>Write promt here</Label> */}
            <ValidatedField id="prompt" name="promt" type="textarea" onChange={e => setPrompt(e.target.value)} />
            <Button
              color="success"
              id="save-entity"
              type="submit"
              outline
              onClick={handleSubmit}
              className="submit-prompt-btn"
              // disabled={props.loading}
            >
              <SendIcon />
            </Button>
          </ModalBody>
        </>
      )}
      {!loading && step == 2 && (
        <div className="Suggestions-wrapper">
          <ModalHeader className="border-0 text-center pt-0" toggle={handleWizard}>
            <Label className="mt-0 fw-bold">Suggestions</Label>
          </ModalHeader>
          <ModalBody>
            <FormGroup row tag="fieldset">
              <legend className="col-form-label col-sm-12 mt-0 mb-2 fw-bold">What will be your assistant's name?(eg: LegalBuddy)</legend>
              <Col sm={12} className="d-flex flex-wrap gap-3">
                {suggestions?.nameSuggestions &&
                  suggestions?.nameSuggestions.map(item => (
                    <div className="radio-btn d-flex gap-2 align-items-center">
                      <Input name={item} type="radio" onClick={e => setName(item)} checked={name == item} required />{' '}
                      <Label check={name == item} className="mb-0">
                        {item}
                      </Label>
                    </div>
                  ))}
              </Col>
              <Col sm={12} className="orMessage pt-2">
                OR
              </Col>
              <Col sm={12}>
                <Input
                  name="name"
                  type="text"
                  placeholder="Assistant's name?(eg: LegalBuddy)"
                  value={name}
                  onChange={e => setName(e.target.value)}
                  label="Knolli Name"
                />{' '}
              </Col>
            </FormGroup>
            <h5 className="fw-bold mt-3 mb-2">Suggested User Profile fields</h5>
            <Accordion open={openFields} toggle={toggleProfileFields}>
              {userProfileFields?.length > 0 &&
                userProfileFields?.map((item, index) => (
                  <AccordionItem key={index}>
                    <AccordionHeader targetId={index}>
                      <>
                        <input
                          type="checkbox"
                          onChange={e => {
                            handleProfileFieldsCheckbox(e, item);
                          }}
                          defaultChecked={selectedProfileFields?.filter(it => it.toString() == item.toString()).length > 0}
                        />
                        <div style={{ margin: '0 8px' }}>{item.name}</div>
                      </>
                    </AccordionHeader>
                    <AccordionBody accordionId={index}>
                      <div>{item.description}</div>
                    </AccordionBody>
                  </AccordionItem>
                ))}
            </Accordion>
            <div className="alignButton d-flex align-items-center justify-content-start gap-3">
              <Input
                name="name"
                type="text"
                placeholder="Add user profile fields"
                value={field}
                onChange={e => setField(e.target.value)}
                label="Add user profile field"
              />
              <Button className="theme-btn btn" onClick={handleAddFields}>
                <FontAwesomeIcon icon="plus" />
                Add
              </Button>
            </div>
            <h5 className="fw-bold mt-3 mb-2">Suggested Document</h5>
            <Accordion open={open} toggle={toggle}>
              {suggestions?.documentSuggestions?.length > 0 &&
                suggestions?.documentSuggestions?.map((item, index) => (
                  <AccordionItem key={index}>
                    <AccordionHeader targetId={index}>
                      <>
                        <input
                          type="checkbox"
                          onChange={e => {
                            handleDocumentCheckbox(e, item);
                          }}
                        />
                        <div style={{ margin: '0 8px' }}>{item.type}</div>
                      </>
                    </AccordionHeader>
                    <AccordionBody accordionId={index}>
                      <div>{item.description}</div>
                    </AccordionBody>
                  </AccordionItem>
                ))}
            </Accordion>
          </ModalBody>
          <ModalFooter className="d-flex justify-content-end align-items-center border-0">
            <Button
              className="theme-btn"
              id="save-entity"
              type="submit"
              outline
              onClick={handleSave}
              disabled={!name || selectedProfileFields?.length == 0}
            >
              Save
            </Button>
          </ModalFooter>
        </div>
      )}
    </Modal>
  );
};

export default WizardModal;
