import '../../content/css/common.css';
import React, { useEffect, useState } from 'react';
import { useParams, useLocation, Link, useNavigate } from 'react-router-dom';
import { Navbar, Nav, NavItem, NavLink } from 'reactstrap';
import { useAppDispatch, useAppSelector } from '../../../../../app/config/store';
import { overridePaginationStateWithQueryParams } from '../../../../../app/shared/util/entity-utils';
// import { createEntity } from '../../entities/ai-chat/ai-chat.reducer';
import { ITEMS_PER_PAGE } from '../../../../../app/shared/util/pagination.constants';

import { CloseIcon, BarsIcon, CollapseIcon, DashboardIcon, ManageIcon } from '../../shared/icon-svg/icon';

// import Markdown from 'react-markdown';
// import remarkGfm from 'remark-gfm';
// import { renderToString } from 'react-dom/server';
interface Props {
  cardCols?: string;
}
// setUserOldConversationId
import { setShowTemplates } from '../../../../../app/entities/chat-dashboard/chat-dashboard.reducer';
import { getPaginationState } from 'react-jhipster';
import { LoadingBar } from 'react-redux-loading-bar';
// import { AccountMenu } from 'app/shared/layout/menus';
import { AccountMenu } from '../../shared/layout/menus';

import { getSession } from '../../../../../app/shared/reducers/authentication';
import { getProfile } from '../../../../../app/shared/reducers/application-profile';
import { hideHeader, showHeader } from '../../shared/layout/header/header.reducer';
import { getAiAssistants, getEntity } from '../../entities/ai-assitant/ai-assitant.reducer';

export const Sidebar = (props: Props) => {
  // const userPromptFullText = useAppSelector(state => state.chatDashboard.userPromptFullText);
  const dispatch = useAppDispatch();
  const [isSidebarCollapsed, setSidebarCollapsed] = useState(false);
  const aiAssitantEntity = useAppSelector(state => state.aiAssitant.entity);
  // const { aiAssitantEntity } = props;

  useEffect(() => {
    dispatch(getSession());
    dispatch(getProfile());
  }, [dispatch]);

  const toggleSidebar = () => {
    setSidebarCollapsed(!isSidebarCollapsed);
  };

  const params = useParams<'id'>().id;
  const id = useParams<'id'>().id;
  // const [userConversation, setUserConversation] = useState('');
  const [userConversationAI, setUserConversationAI] = useState<any>();
  const [userConversationId, setUserConversationId] = useState(null);
  // const showTemplates = useAppSelector(state => state.chatDashboard.showTemplates);
  // const [loading, setLoading] = useState(false);
  const [timeoutLoading, setTimeoutLoading] = useState(true);
  // const [showLoading, setShowLoading] = useState(true);
  // const userOldConversationId = useAppSelector(state => state.chatDashboard.userOldConversationId);
  // const [generatingChatGptConversationId, setGeneratingChatGptConversationId] = useState('');
  const newConversation = useAppSelector(state => state.aIChat.entity);
  const [userNameInital, setUserNameInital] = useState('');
  var dataList = useAppSelector(state => state.aIChat.entities);
  const account = useAppSelector(state => state.authentication.account);
  const pageLocation = useLocation();
  const [error, setError] = useState('');
  // const toggleMenu = () => setMenuOpen(!menuOpen);
  // const [menuOpen, setMenuOpen] = useState(false);
  // const [isOpen, setIsOpen] = useState(false);
  // const [userSelectedConversationId, setUserSelectedConversationId] = useState(null);
  // const [isOpenFavourites, setIsOpenFavourites] = useState(false);
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  const navigate = useNavigate();
  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getPaginationState(pageLocation, ITEMS_PER_PAGE, 'id'), pageLocation.search),
  );
  const [isAssistantVisited, setIsAssistantVisited] = useState<any>();
  // const [isManageVisited, setIsManageVisited] = useState<any>();

  const aiAssitantList = useAppSelector(state => state.aiAssitant.entities.data);

  const filterAssistantRole = aiAssitantList?.find(i => i.admin === 'ADMIN' && i.assistantId == params);

  const configUserChars = account => {
    if (account.firstName != null && account.firstName != undefined && account.firstName != '') {
      setUserNameInital(account.firstName.charAt(0));
    } else {
      setUserNameInital(account?.email.charAt(0));
    }
  };

  const [chatTitleLoaded, setChatTitleLoaded] = useState({
    chatId: null,
    isTitleLoaded: false,
  });

  useEffect(() => {}, [params]);

  let path;
  useEffect(() => {
    path = location.pathname.split('/');
    if (path.length == 3 && path[2] == 'manage') {
      setIsAssistantVisited('manageAssistant');
    }
    if (path.length == 4) {
      setIsAssistantVisited('assistantEdit');
    }
    if (path.length == 5) {
      if (path[4] == 'documents') setIsAssistantVisited('manageDocs');
      if (path[4] == 'team') setIsAssistantVisited('teamManagement');
      if (path[4] == 'fields') setIsAssistantVisited('profileFields');
    }
  }, [location]);

  useEffect(() => {
    if (id) dispatch(getEntity(id));
  }, [id]);

  useEffect(() => {
    dispatch(
      getAiAssistants({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
      }),
    );
  }, []);

  useEffect(() => {
    // Run code only for new conversations
    let newChatId = newConversation.id;
    if (newConversation.title == null) {
      setUserConversationId(newChatId);
      handleUpdateChatTitle(newChatId, false);
      setShowTemplates(true);
      // dispatch(
      //   getEntities({
      //     page: paginationState.activePage - 1,
      //     size: paginationState.itemsPerPage,
      //     sort: `${paginationState.sort},${paginationState.order}`,
      //   }),
      // );
    }
  }, [newConversation]);

  useEffect(() => {}, [userConversationAI]);

  useEffect(() => {
    if (dataList && dataList.length > 0) {
      let theChat = dataList.find(x => x.user?.id == account?.id);
    } else {
      setTimeout(() => {
        setTimeoutLoading(false);
      }, 1000);
    }
  }, [dataList]);

  useEffect(() => {
    localStorage.setItem('context', 'all');
    configUserChars(account);
  }, []);

  const handleUpdateChatTitle = (chatId, isTitleLoaded) => {
    setChatTitleLoaded({
      ...chatTitleLoaded,
      isTitleLoaded: isTitleLoaded,
      chatId: chatId,
    });
  };

  // useEffect(() => {
  //   if (account && account.id) {
  //     dispatch(
  //       getEntities({
  //         page: paginationState.activePage - 1,
  //         size: paginationState.itemsPerPage,
  //         sort: `${paginationState.sort},${paginationState.order}`,
  //       }),
  //     );
  //   }
  // }, [account]);

  // const reloadAllEntitiesOnStreamingEnd = () => {
  //   if (userConversationId == chatTitleLoaded.chatId && !chatTitleLoaded.isTitleLoaded) {
  //     // dispatch(
  //     //   getEntities({
  //     //     page: paginationState.activePage - 1,
  //     //     size: paginationState.itemsPerPage,
  //     //     sort: `${paginationState.sort},${paginationState.order}`,
  //     //   }),
  //     // );
  //   }
  //   setChatTitleLoaded({
  //     ...chatTitleLoaded,
  //     isTitleLoaded: true,
  //   });
  // };

  // const handleUserSelectedConversation = async (id, oldConversation) => {
  //   dispatch(setUserOldConversationId(id));

  //   if (id == generatingChatGptConversationId) {
  //     dispatch(setShowTemplates(false));
  //     setUserConversation(oldConversation + userPromptFullText + (userConversationAI ? userConversationAI : ''));
  //     setShowLoading(true);
  //   } else {
  //     if (oldConversation == '') {
  //       dispatch(setShowTemplates(true));
  //     }
  //     setUserConversation(oldConversation);
  //     setShowLoading(false);
  //   }
  // };

  // const onClickStartNewChatPlusButton = async () => {
  //   setUserConversation('');
  //   setUserSelectedConversationId(null);
  //   setShowTemplates(true);
  //   await dispatch(createEntity({}));
  // };

  // const updateConversation = async generatedMessage => {
  //   let aiResp = renderToString(
  //     <Markdown
  //       remarkPlugins={[remarkGfm]}
  //       components={{
  //         a: ({ node, ...props }) => {
  //           return (
  //             <a {...props} target="_blank">
  //               {props.children}
  //             </a>
  //           );
  //         },
  //       }}
  //     >
  //       {generatedMessage}
  //     </Markdown>,
  //   );
  //   setUserConversation(userConversation => userConversation + aiResp);
  // };

  // const [dropdownOpen, setDropdownOpen] = useState(false);

  // const toggle = () => setDropdownOpen(prevState => !prevState);

  useEffect(() => {
    dispatch(hideHeader()); // Hide header when this component is mounted
    return () => {
      dispatch(showHeader()); // Show header again when leaving this route
    };
  }, [dispatch]);

  return (
    <div className={`mobilesidebar_open bg-clrside side-bar p-0 flex ${isSidebarCollapsed ? 'collapsed' : ''}`}>
      <button onClick={toggleSidebar} className="sidebar-toggle-btn">
        {isSidebarCollapsed ? <CollapseIcon /> : <CollapseIcon />}
      </button>
      <div className={'open-desktop-sidebar w-100 chat_history'}>
        <div className="profile-display">
          <div className="mobile_display mb-2">
            <img className="logo" src="content/images/knolli-logo-img.svg" alt="Logo" onClick={() => navigate('/admin/dashboard')} />
            <img className="logo-icon" src="content/images/knolli-icon.svg" alt="Logo-icon" onClick={() => navigate('/admin/dashboard')} />
            <div className="d-flex justify-content-start">
              {/* <Button color="primary" className="" onClick={onClickStartNewChatPlusButton}>
                  Start new chat
                </Button> */}
            </div>
          </div>
          {error && <div className="text-danger">{error}</div>}
        </div>
        <div className="ai-chat-list-view">
          <div className="mobile_display mb-2">
            <div className="d-flex justify-content-start">
              <Nav pills vertical>
                <NavItem className={location.pathname === '/admin/dashboard' ? 'active' : ''}>
                  <NavLink to="/admin/dashboard" tag={Link}>
                    <DashboardIcon /> <span className="text-truncate w-100 d-inline-block">Dashboard</span>
                  </NavLink>
                </NavItem>
                <NavItem className={isAssistantVisited === 'manageAssistant' ? 'active' : ''}>
                  <NavLink to="/admin/manage" tag={Link}>
                    <ManageIcon /> <span className="text-truncate w-100 d-inline-block">Manage Knollis</span>
                  </NavLink>
                </NavItem>
                {filterAssistantRole?.admin === 'ADMIN' &&
                  (isAssistantVisited === 'assistantEdit' ||
                    isAssistantVisited == 'manageDocs' ||
                    isAssistantVisited == 'teamManagement' ||
                    isAssistantVisited == 'profileFields') && (
                    <NavItem className={isAssistantVisited == 'assistantEdit' ? 'active' : ''}>
                      <NavLink to={`/admin/manage/${aiAssitantEntity.id}`} tag={Link}>
                        <span className="text-truncate w-100 d-inline-block">{aiAssitantEntity.qdrantCollectionName}</span>
                      </NavLink>
                      <Nav vertical>
                        <NavItem className={isAssistantVisited == 'manageDocs' ? 'active' : ''}>
                          <NavLink to={`/admin/manage/${aiAssitantEntity.id}/documents`} tag={Link}>
                            <span className="text-truncate w-100 d-inline-block">Documents</span>
                          </NavLink>
                        </NavItem>
                        <NavItem
                          isopen="true"
                          key="sideproductAppNameitem"
                          className={isAssistantVisited == 'teamManagement' ? 'active' : ''}
                        >
                          <NavLink key="sideproductAppName" to={`/admin/manage/${aiAssitantEntity.id}/team`} className="" tag={Link}>
                            <span className="text-truncate w-100 d-inline-block">Team</span>
                          </NavLink>
                        </NavItem>
                        <NavItem isopen="true" key="profileFields" className={isAssistantVisited == 'profileFields' ? 'active' : ''}>
                          <NavLink key="sideproductAppName" to={`/admin/manage/${aiAssitantEntity.id}/fields`} className="" tag={Link}>
                            Profile Fields
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </NavItem>
                  )}
              </Nav>
            </div>
          </div>
        </div>
        <div id="sidebar_user_profile">
          <LoadingBar className="loading-bar" />
          <Navbar data-cy="navbar" dark expand="md" className="jh-navbar">
            <Nav id="header-tabs" className="ms-auto" navbar vertical>
              <NavItem className="upgrade">
                <NavLink href="/plans" className="btn btn btn-outline-theme-clr">
                  Upgrade
                </NavLink>
              </NavItem>
              <AccountMenu isAuthenticated={isAuthenticated} />
            </Nav>
          </Navbar>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
