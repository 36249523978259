import React from 'react';
import { Translate } from 'react-jhipster';
import { Alert, Button, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
4;

import { ErrorImage } from '../../app/shared/icon-svg/icon';

const PageNotFound = () => {
  return (
    <Row>
      <div className="page-not-found-wrapper px-3 pb-3">
        {/* <Alert>
            <Translate contentKey="error.http.404">The page does not exist.</Translate>
        </Alert> */}
        <div className="position-fixed top-nav-bar">
          <img src="../../../../../content/images/knolli-logo-img.png" alt="knolli-logo" />

          <Button color="text">Contact Us</Button>
        </div>
        <div className="container">
          <div className="d-flex align-items-center justify-content-center flex-grow-1 error-img">
            <ErrorImage />
          </div>
          <h3>Looks like this page doesn't exist</h3>
          <p>Go back to home and continue exploring</p>
          <Link to="/" className="theme-btn d-flex align-items-center justify-content-center gap-2">
            <FontAwesomeIcon icon="arrow-left" />
            Back to Home
          </Link>
        </div>
      </div>
    </Row>
  );
};

export default PageNotFound;
