import React from 'react';
import { Translate } from 'react-jhipster';

import MenuItem from 'app/shared/layout/menus/menu-item';

const EntitiesMenu = () => {
  return (
    <>
      {/* prettier-ignore */}
      <MenuItem icon="asterisk" to="/ai-chat">
        <Translate contentKey="global.menu.entities.aiChat" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/generated-message">
        <Translate contentKey="global.menu.entities.generatedMessage" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/user-prompt">
        <Translate contentKey="global.menu.entities.userPrompt" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/source-document">
        <Translate contentKey="global.menu.entities.sourceDocument" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/source-document-text-segment">
        <Translate contentKey="global.menu.entities.sourceDocumentTextSegment" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/file-upload">
        <Translate contentKey="global.menu.entities.fileUpload" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/user-credits-balance">
        <Translate contentKey="global.menu.entities.userCreditsBalance" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/user-credits-deposit">
        <Translate contentKey="global.menu.entities.userCreditsDeposit" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/semantic-search-entity">
        <Translate contentKey="global.menu.entities.semanticSearchEntity" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/knolli-ai-user-profile">
        <Translate contentKey="global.menu.entities.knolliAiUserProfile" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/ai-assitant">
        <Translate contentKey="global.menu.entities.aiAssitant" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/user-group">
        <Translate contentKey="global.menu.entities.userGroup" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/group">
        <Translate contentKey="global.menu.entities.group" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/knolli-ai-user-profile-field">
        <Translate contentKey="global.menu.entities.knolliAiUserProfileField" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/knolli-ai-user-profile-field-value">
        <Translate contentKey="global.menu.entities.knolliAiUserProfileFieldValue" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/user-profile">
        <Translate contentKey="global.menu.entities.userProfile" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/user-profile-field-value">
        <Translate contentKey="global.menu.entities.userProfileFieldValue" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/user-profile-field">
        <Translate contentKey="global.menu.entities.userProfileField" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/user-intent">
        <Translate contentKey="global.menu.entities.userIntent" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/invite-user-request">
        <Translate contentKey="global.menu.entities.inviteUserRequest" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/user-subscription">
        <Translate contentKey="global.menu.entities.userSubscription" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/subscription-plan">
        <Translate contentKey="global.menu.entities.subscriptionPlan" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/wait-list">
        <Translate contentKey="global.menu.entities.waitList" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/email-job">
        <Translate contentKey="global.menu.entities.emailJob" />
      </MenuItem>
      {/* jhipster-needle-add-entity-to-menu - JHipster will add entities to the menu here */}
    </>
  );
};

export default EntitiesMenu;
