import { IAiAssitant } from 'app/shared/model/ai-assitant.model';
import { GroupType } from 'app/shared/model/enumerations/group-type.model';

export interface IGroup {
  id?: number;
  name?: string;
  description?: string | null;
  type?: keyof typeof GroupType;
  aiAssitant?: IAiAssitant | null;
}

export const defaultValue: Readonly<IGroup> = {};
