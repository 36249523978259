import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row } from 'reactstrap';
import { useAppDispatch, useAppSelector } from '../../../../../app/config/store';
import WaitList from '../wait-list/wait-list';
import { hideHeader, showHeader } from '../../shared/layout/header/header.reducer';

export const Home = () => {
  const dispatch = useAppDispatch();
  // const showModalLogin = useAppSelector(state => state.authentication.showModalLogin);
  // const loginError = useAppSelector(state => state.authentication.loginError);
  const accountdetails = useAppSelector(state => state.authentication.account);
  const accountdetailsloading = useAppSelector(state => state.authentication.loading);

  // const [showModal, setShowModal] = useState(showModalLogin);
  const navigate = useNavigate();

  // const handleClose = () => {
  //   setShowModal(false);
  //   navigate('/');
  // };

  useEffect(() => {
    if (accountdetailsloading === false && accountdetails && typeof accountdetails === 'object' && accountdetails?.activated) {
      navigate('/admin/dashboard');
    } else {
      dispatch(hideHeader());
    }
  }, [accountdetails, accountdetailsloading]);

  useEffect(() => {
    return () => {
      dispatch(showHeader());
    };
  }, [dispatch]);

  return (
    <Row>
      {!accountdetailsloading ? (
        <WaitList />
      ) : (
        <div className="app-loading" style={{ height: '500px', width: '300px', marginInline: 'auto' }}>
          <div className="conductor_loader-index"></div>
        </div>
      )}
    </Row>
  );
};

export default Home;
