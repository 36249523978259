import dayjs from 'dayjs';
import { IUser } from 'app/shared/model/user.model';
import { CreditsType } from 'app/shared/model/enumerations/credits-type.model';

export interface IUserCreditsBalance {
  id?: number;
  type?: keyof typeof CreditsType | null;
  balance?: number | null;
  lastCreditsUpdateTimestamp?: dayjs.Dayjs | null;
  lastCreditsUpdateDescription?: string | null;
  user?: IUser | null;
}

export const defaultValue: Readonly<IUserCreditsBalance> = {};
