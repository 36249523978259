import React from 'react';
// import MenuItem from 'app/shared/layout/menus/menu-item';
import MenuItem from './menu-item';
import { DropdownItem, Label } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Translate, translate } from 'react-jhipster';
import { getLoginUrl } from '../../../../../../app/shared/util/url-utils';
import { NavDropdown } from './menu-components';
import { faDashboard } from '@fortawesome/free-solid-svg-icons';

const accountMenuItemsAuthenticated = () => (
  <>
    <MenuItem icon={faDashboard} to="/admin/dashboard" data-cy="faDashboard">
      Admin Dashboard
    </MenuItem>
    <MenuItem icon="user" to="/admin/account-settings" data-cy="account-settings">
      Account Settings
    </MenuItem>
    <MenuItem icon="sign-out-alt" to="/logout" data-cy="logout">
      <Translate contentKey="global.menu.account.logout">Sign out</Translate>
    </MenuItem>
  </>
);

const accountMenuItems = () => (
  <>
    <DropdownItem id="login-item" tag="a" href={getLoginUrl()} data-cy="login">
      <FontAwesomeIcon icon="sign-in-alt" /> <Translate contentKey="global.menu.account.login">Sign in</Translate>
    </DropdownItem>
  </>
);

export const AccountMenu = ({ isAuthenticated = false }) => (
  <NavDropdown icon="user" name={translate('global.menu.account.main')} id="account-menu" data-cy="accountMenu">
    {isAuthenticated && accountMenuItemsAuthenticated()}
    {!isAuthenticated && accountMenuItems()}
  </NavDropdown>
);

export default AccountMenu;
