import { IKnolliAIUserProfileField } from 'app/shared/model/knolli-ai-user-profile-field.model';
import { IKnolliAIUserProfile } from 'app/shared/model/knolli-ai-user-profile.model';

export interface IKnolliAIUserProfileFieldValue {
  id?: number;
  value?: string;
  knolliAIUserProfileField?: IKnolliAIUserProfileField | null;
  knolliAIUserProfile?: IKnolliAIUserProfile | null;
}

export const defaultValue: Readonly<IKnolliAIUserProfileFieldValue> = {};
