import '../../../../../app/modules/home/home.scss';
import React, { useEffect } from 'react';
import { Route, Navigate } from 'react-router-dom';
import AppDashboard from '../app-dashboard/app-dashboard';
import AccountSettings from '../account-settings/account-settings';
import AddAiAssistant from '../ai-assistant/add-ai-assistant';
import TeamMembersManagement from '../team-member-management/team-member-management';
import ManageDocument from '../manage-docs/manage-docs';
import ErrorBoundaryRoutes from '../../../../../app/shared/error/error-boundary-routes';
import ManageAiAssitant from '../ai-assistant/managae-ai-assistant';
import UserProfileFields from '../user-profile-fields/user-profile-fields';
import PageNotFound from '../../error/page-not-found';

export const AdminDash = () => {
  return (
    <ErrorBoundaryRoutes>
      <Route path="/">
        <Route index element={<Navigate to="/admin/dashboard" replace />} />
        <Route path="dashboard" element={<AppDashboard />} />
        <Route path="account-settings" element={<AccountSettings />} />
        <Route path="manage">
          <Route index element={<ManageAiAssitant />} />
          <Route path="new" element={<AddAiAssistant />} />
          <Route path=":id/*">
            <Route index element={<AddAiAssistant />} />
            <Route path="team" element={<TeamMembersManagement />} />
            <Route path="fields" element={<UserProfileFields />} />
            <Route path="documents" element={<ManageDocument />} />
          </Route>
        </Route>
        <Route path="*" element={<PageNotFound />} />
      </Route>
    </ErrorBoundaryRoutes>
  );
};

export default AdminDash;
