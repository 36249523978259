import React, { useEffect, useState } from 'react';
import { useStripe, useElements, CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
import './stripe.scss';
import { toast } from 'react-toastify';
import { createStripePayment } from '../../entities/subscription-plan/subscription-plan.reducer';
import { useAppDispatch } from '../../../../../app/config/store';
import { useNavigate } from 'react-router';

function SubscriptionForm({ title, price, toggleModal, plan_id, plan_div_id }) {
  const [loading, setLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  console.log('plan_div_id', plan_div_id);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const handleSubmit = async event => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet
      return;
    }

    setLoading(true);

    const cardNumberElement = elements.getElement(CardNumberElement);
    const cardExpiryElement = elements.getElement(CardExpiryElement);
    const cardCvcElement = elements.getElement(CardCvcElement);

    // Create PaymentMethod
    const { paymentMethod, error } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardNumberElement, // We pass cardNumberElement here
    });

    if (error) {
      toast.warn(error.message);
      setLoading(false);
      return;
    }

    console.log('PaymentMethod:', paymentMethod);

    const subscriptionData = await dispatch(createStripePayment({ paymentMethodId: paymentMethod.id, planId: plan_id }));

    console.log('subscriptionData', subscriptionData);

    if (subscriptionData && !subscriptionData.error) {
      toast.success('Subscription created successfully!');
      toggleModal();
      navigate('/');
    } else {
      console.error('Error occured during payment', subscriptionData);
      toast.error('Subscription failed');
    }

    setLoading(false);
  };

  return (
    <div className="subscription-form-container">
      <form className="subscription-form" onSubmit={handleSubmit}>
        <p className="price">Amount: {price}</p>

        <div className="form-group">
          <label htmlFor="card-number">Card Number</label>
          <div id="card-number" className="card-element">
            <CardNumberElement />
          </div>
        </div>

        <div className="form-group">
          <label htmlFor="card-expiry">Expiry Date</label>
          <div id="card-expiry" className="card-element">
            <CardExpiryElement />
          </div>
        </div>

        <div className="form-group">
          <label htmlFor="card-cvc">CVC</label>
          <div id="card-cvc" className="card-element">
            <CardCvcElement />
          </div>
        </div>

        <button className="btn active btn-outline-theme-clr" type="submit" disabled={!stripe || loading}>
          {loading ? 'Processing...' : 'Subscribe'}
        </button>

        <button type="button" className="close-button" onClick={toggleModal}>
          Close
        </button>
      </form>
      {/* <div id="add_stripe_section" style={{ width: '100%' }} /> */}
    </div>
  );
}

export default SubscriptionForm;
