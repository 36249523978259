import dayjs from 'dayjs';
import { IUser } from 'app/shared/model/user.model';
import { IAiAssitant } from 'app/shared/model/ai-assitant.model';
import { IUserIntent } from 'app/shared/model/user-intent.model';

export interface IUserPrompt {
  id?: number;
  promptText?: string;
  createdDate?: dayjs.Dayjs | null;
  updatedDate?: dayjs.Dayjs | null;
  deletedDate?: dayjs.Dayjs | null;
  user?: IUser | null;
  aiAssitant?: IAiAssitant | null;
  userIntent?: IUserIntent | null;
}

export const defaultValue: Readonly<IUserPrompt> = {};
