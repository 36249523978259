import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Card, CardBody, CardText, CardTitle, Col, Label, Row, Table, Tooltip } from 'reactstrap';
import { getPaginationState, JhiPagination, JhiItemCount } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from '../../../../../app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from '../../../../../app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from '../../../../../app/config/store';

import { getAiAssistants } from '../../entities/ai-assitant/ai-assitant.reducer';
import Sidebar from '../sidebar/sidebar';
import '../../app.scss';
import { UpRightArrowIcon } from '../../shared/icon-svg/icon';
import { analyticsTrack } from '../../shared/services/analytics';
import { reset } from '../../entities/knolli-ai-user-profile-field/knolli-ai-user-profile-field.reducer';
import WizardModal from './WizardModal';
import { AiGeneratedImage } from '../../shared/icon-svg/icon';

export const ManageAiAssitant = () => {
  const dispatch = useAppDispatch();

  const pageLocation = useLocation();
  const navigate = useNavigate();

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getPaginationState(pageLocation, ITEMS_PER_PAGE, 'id'), pageLocation.search),
  );

  const aiAssitantList = useAppSelector(state => state.aiAssitant.entities.data);
  const loading = useAppSelector(state => state.aiAssitant.loading);
  const totalItems = useAppSelector(state => state.aiAssitant.totalItems);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [showWizardModal, setShowWizardModal] = useState(false);
  // const [aiAssistants, setAiAssistants] = useState(aiAssitantList);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  const getAllEntities = () => {
    dispatch(
      getAiAssistants({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
      }),
    );
    dispatch(reset());
  };

  const sortEntities = () => {
    getAllEntities();
    // const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    // if (pageLocation.search !== endURL) {
    //   navigate(`${pageLocation.pathname}${endURL}`);
    // }
  };

  useEffect(() => {
    if (aiAssitantList == undefined || aiAssitantList.length == 0) sortEntities();
    analyticsTrack('Manage Knolli Page');
  }, []);

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  useEffect(() => {
    const params = new URLSearchParams(pageLocation.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [pageLocation.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const addAssistant = () => {
    analyticsTrack('Adding New Knolli CTA');
    navigate('/admin/manage/new');
  };
  // const handleSyncList = () => {
  //   sortEntities();
  // };

  // const getSortIconByFieldName = (fieldName: string) => {
  //   const sortFieldName = paginationState.sort;
  //   const order = paginationState.order;
  //   if (sortFieldName !== fieldName) {
  //     return faSort;
  //   } else {
  //     return order === ASC ? faSortUp : faSortDown;
  //   }
  // };

  const handleWizard = () => {
    setShowWizardModal(!showWizardModal);
  };

  return (
    <>
      <div className="dashboard-app-p">
        <Sidebar />
        <div className="is_open p-3">
          <h2
            id="ai-assitant-heading"
            data-cy="AiAssitantHeading"
            className="main-title d-flex justify-content-between gap-3 align-items-center"
          >
            Your Knollis
            <div className="d-flex justify-content-end">
              <Button className="theme-btn btn" onClick={handleWizard} style={{ marginRight: '8px' }}>
                <AiGeneratedImage />
                &nbsp; Wizard
              </Button>
              <Button className="theme-btn btn" onClick={addAssistant}>
                <FontAwesomeIcon icon="plus" />
                &nbsp; Add a Knolli
              </Button>
            </div>
          </h2>
          {loading && (
            <Col className="col-md-12">
              <div className="app-loading" style={{ height: '500px', width: '300px', marginInline: 'auto' }}>
                <div className="conductor_loader-index"></div>
              </div>
            </Col>
          )}

          {!loading && aiAssitantList?.length > 0 ? (
            <div className="card-wrapper pt-4 p-3">
              <Row className="cardLayout">
                {aiAssitantList.map((assistant, i) => (
                  <Col md={'12'} Col sm={'12'} lg={'4'} xl={'3'} key={`entity-${i}`} data-cy="entityTable" className="mb-4">
                    <Card>
                      <CardBody
                        className="product-name d-flex flex-column p-0"
                        tag={Link}
                        // to={`/copilot/${assistant.assistantId}`}
                        style={{ textDecoration: 'none !important' }}
                      >
                        <div className="static-title d-flex gap-2 align-items-center justify-content-between p-3 border-bottom">
                          <CardText className="ProductImg mb-0">
                            <span style={{ width: '42px' }}>
                              {assistant.collectionName ? assistant.collectionName.charAt(0).toUpperCase() : ''}
                            </span>
                          </CardText>
                          {assistant.admin == 'ADMIN' && (
                            <Button
                              className="p-0"
                              tag={Link}
                              to={`/admin/manage/${assistant.assistantId}`}
                              // color="primary"
                              size="sm"
                              data-cy="entityEditButton"
                              style={{ background: 'none', border: 'none' }}
                            >
                              <FontAwesomeIcon icon="pencil-alt" color="#00523F" />{' '}
                            </Button>
                          )}
                        </div>
                        <CardTitle className="px-3" style={{ borderBottom: 'none' }}>
                          {assistant.collectionName}
                          <div className="d-flex justify-content-end">
                            <Link to={`/copilot/${assistant.assistantId}`} id="Tooltip-">
                              <UpRightArrowIcon />
                            </Link>
                            <Tooltip placement="top" isOpen={tooltipOpen} text="Hello!" target={'Tooltip-'} toggle={toggle}>
                              Go To Assistant!
                            </Tooltip>
                          </div>
                        </CardTitle>
                        {/* <CardText className="apps-tittle border-0 p-3 flex-grow-1 align-items-start">
                        <div className="apps-tittle-inner">
                          <span style={{ fontWeight: 800 }}>System Message: </span>
                          <span>{assistant.systemMessage}</span>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '0 14px 10px 0' }}>
                          <Link to={`/copilot/${assistant.assistantId}`} id="Tooltip-">
                            <UpRightArrowIcon />
                          </Link>
                          <Tooltip placement="top" isOpen={tooltipOpen} text="Hello!" target={'Tooltip-'} toggle={toggle}>
                            Go To Assistant!
                          </Tooltip>
                        </div>
                      </CardText> */}
                      </CardBody>
                    </Card>
                  </Col>
                ))}
              </Row>
            </div>
          ) : (
            !loading &&
            aiAssitantList?.length == 0 && (
              <div className="alert alert-warning">
                <Label>No Knollis found</Label>
              </div>
            )
          )}
          {totalItems ? (
            <div className={aiAssitantList && aiAssitantList.length > 0 ? 'footer-pagination' : 'd-none'}>
              <div className="justify-content-center d-flex">
                <JhiItemCount
                  page={paginationState.activePage}
                  total={totalItems}
                  itemsPerPage={paginationState.itemsPerPage}
                  i18nEnabled
                />
              </div>
              <div className="justify-content-center d-flex">
                <JhiPagination
                  activePage={paginationState.activePage}
                  onSelect={handlePagination}
                  maxButtons={5}
                  itemsPerPage={paginationState.itemsPerPage}
                  totalItems={totalItems}
                />
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
      {showWizardModal && <WizardModal handleWizard={handleWizard} />}
    </>
  );
};

export default ManageAiAssitant;
