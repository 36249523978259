import { IAiAssitant } from 'app/shared/model/ai-assitant.model';
import { UserProfileFieldType } from 'app/shared/model/enumerations/user-profile-field-type.model';
import { UserProfileFieldStatus } from 'app/shared/model/enumerations/user-profile-field-status.model';

export interface IUserProfileField {
  id?: number;
  name?: string;
  type?: keyof typeof UserProfileFieldType | null;
  description?: string | null;
  status?: keyof typeof UserProfileFieldStatus | null;
  aiAssitant?: IAiAssitant | null;
}

export const defaultValue: Readonly<IUserProfileField> = {};
