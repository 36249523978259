import '../../content/css/manage-docs.scss';
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Table, Label } from 'reactstrap';
import { getPaginationState, JhiPagination, JhiItemCount, ValidatedField } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from '../../../../../app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from '../../../../../app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from '../../../../../app/config/store';
import { getEntities } from '../../../../../app/entities/file-upload/file-upload.reducer';
import Sidebar from '../sidebar/sidebar';
import { uploadFile } from '../../entities/ai-chat/ai-chat.reducer';
import { toast } from 'react-toastify';
import { hideHeader, showHeader } from '../../shared/layout/header/header.reducer';

export const ManageDocument = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams<'id'>();
  const pageLocation = useLocation();
  const navigate = useNavigate();

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getPaginationState(pageLocation, ITEMS_PER_PAGE, 'id'), pageLocation.search),
  );

  const sourceDocumentList = useAppSelector(state => state.fileUpload.entities);
  const loading = useAppSelector(state => state.sourceDocument.loading);
  const totalItems = useAppSelector(state => state.sourceDocument.totalItems);
  const [modal, setModal] = useState(false);
  const [file, setFile] = useState();

  const toggle = () => setModal(!modal);

  const getAllEntities = () => {
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
        query: `aiAssitantId.equals=${id}`,
      }),
    );
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (pageLocation.search !== endURL) {
      navigate(`${pageLocation.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  useEffect(() => {
    const params = new URLSearchParams(pageLocation.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [pageLocation.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    sortEntities();
  };

  const uploadDoc = async () => {
    let resp = await dispatch(
      uploadFile({
        file: file,
        aiAssistantId: id,
        fileUseType: 'SOURCE_DOCUMENT',
      }),
    );

    // console.log('resp', resp);
    if (resp && resp.payload && resp.payload.data.status == 'success') {
      toast('Document uploaded successfully');
      sortEntities();
      toggle();
    }
  };

  const setSelectedFile = event => {
    // console.log("event",event);
    setFile(event.target.files[0]);
  };

  const getSortIconByFieldName = (fieldName: string) => {
    const sortFieldName = paginationState.sort;
    const order = paginationState.order;
    if (sortFieldName !== fieldName) {
      return faSort;
    } else {
      return order === ASC ? faSortUp : faSortDown;
    }
  };

  useEffect(() => {
    dispatch(hideHeader()); // Hide header when this component is mounted
    return () => {
      dispatch(showHeader()); // Show header again when leaving this route
    };
  }, [dispatch]);

  return (
    <div className="dashboard-app-p overflow-hidden">
      <Sidebar />
      <div className={'is_open p-3 d-flex flex-column'}>
        <h2 className="main-title" id="source-document-heading" data-cy="SourceDocumentHeading">
          Documents Center
        </h2>
        <p>Upload relevant documents to you knolli to make it better.</p>
        <div className="card-wrapper mt-2">
          <div className={'p-3 d-flex gap-3 flex-wrap align-items-center border-bottom'}>
            {/* <div className="heading-sec">
              <h2>Manage Documents</h2>
              <span>Upload, manage, or delete documents</span>
            </div> */}
            <div className="flex-grow-1">
              <Button className="theme-btn" style={{ float: 'right' }} onClick={toggle}>
                Upload
              </Button>
            </div>
            {/* <div className="">
              <Button className="theme-btn">Download All</Button>
            </div> */}
          </div>
          <div className="theme-table mx-3 mt-3">
            {sourceDocumentList && sourceDocumentList.length > 0 ? (
              <Table responsive>
                <thead>
                  <tr>
                    <th className="hand" onClick={sort('title')}>
                      File
                      <FontAwesomeIcon icon={getSortIconByFieldName('title')} />
                    </th>
                    <th className="hand" onClick={sort('documentType')}>
                      Upload date
                      <FontAwesomeIcon icon={getSortIconByFieldName('documentType')} />
                    </th>
                    <th className="hand" onClick={sort('sourceLocation')}>
                      Size
                      <FontAwesomeIcon icon={getSortIconByFieldName('sourceLocation')} />
                    </th>
                    <th className="hand" onClick={sort('fileLocation')}>
                      Type
                      <FontAwesomeIcon icon={getSortIconByFieldName('fileLocation')} />
                    </th>
                    <th className="hand" onClick={sort('fileName')}>
                      Last synced
                      <FontAwesomeIcon icon={getSortIconByFieldName('fileName')} />
                    </th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {sourceDocumentList.map((sourceDocument, i) => (
                    <tr key={`entity-${i}`} data-cy="entityTable">
                      <td>{sourceDocument.fileName}</td>
                      <td>{sourceDocument.createdDate}</td>
                      <td>{sourceDocument.sourceLocation}</td>
                      <td>{sourceDocument.fileLocation}</td>
                      <td>{sourceDocument.updatedDate}</td>
                      <td className="text-end">
                        <div className="btn-group flex-btn-group-container">
                          {/* <Button
                            tag={Link}
                            to={`/source-document/${sourceDocument.id}`}
                            className="theme-btn"
                            size="sm"
                            data-cy="entityDetailsButton"
                          >
                            <span className="d-none d-md-inline">Modify</span>
                          </Button> */}
                          {/* <Dropdown></Dropdown> */}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              !loading && <div className="alert alert-warning">No Source Documents found</div>
            )}
          </div>
        </div>
        {totalItems ? (
          <div className={sourceDocumentList && sourceDocumentList.length > 0 ? '' : 'd-none'}>
            <div className="justify-content-center d-flex">
              <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} i18nEnabled />
            </div>
            <div className="justify-content-center d-flex">
              <JhiPagination
                activePage={paginationState.activePage}
                onSelect={handlePagination}
                maxButtons={5}
                itemsPerPage={paginationState.itemsPerPage}
                totalItems={totalItems}
              />
            </div>
          </div>
        ) : (
          ''
        )}

        <Modal isOpen={modal} toggle={toggle}>
          <ModalHeader className="border-0" toggle={toggle}>
            Upload Document
          </ModalHeader>
          <ModalBody className="pt-0">
            <div className="Upload-img-sec p-3">
              <Label className="title m-0">Starting File</Label>
              <div className="my-3 file-upload-wrapper">
                <ValidatedField className="fileUploader" type="file" name="document" onChange={event => setSelectedFile(event)} />
              </div>
            </div>
          </ModalBody>
          <ModalFooter className="border-0">
            <Button className="theme-btn" onClick={toggle}>
              Cancel
            </Button>
            <Button className="theme-btn" onClick={uploadDoc}>
              Upload
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </div>
  );
};

export default ManageDocument;
