import { AnalyticsBrowser } from '@segment/analytics-next';
import * as amplitude from '@amplitude/analytics-browser';

amplitude.init('0b3d3d2acb06906a726212be3a0668e1', { autocapture: true });

export const segmentAnalytics = AnalyticsBrowser.load({ writeKey: '5LwhieiYBd8vb4KdujORiTEEtPyaqOeW' });

export const amplitudeAnalytics = amplitude;

export const analyticsTrack = (eventName, eventProperties = {}) => {
  segmentAnalytics.track(eventName, eventProperties);
  amplitudeAnalytics.track(eventName, eventProperties);
};

// module.exports = {segmentAnalytics: segmentAnalytics, amplitudeAnalytics: amplitudeAnalytics}
