import './wait-list.scss';
import WaitlistFormView from './WaitlistFormView';
import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Col, Button } from 'reactstrap';
interface Props {
  handleViews?: any;
  id?: any;
  parentPage?: any;
  cardCols?: any;
}

export const WaitList = (props: Props) => {
  const [showViews, setShowViews] = useState('');
  const [id, setId] = useState(undefined);
  const location = useLocation();
  const params = useParams<'id'>().id;
  // const { state } = location;

  useEffect(() => {}, [params]);

  const handleViews = (type, id) => {
    setShowViews(type);
    setId(id);
  };

  return (
    <Col xs="12" className="waitlist-view p-0">
      <div className="position-fixed top-nav-bar">
        <img src="../../../../../content/images/knolli-logo-img.png" alt="knolli-logo" />

        <Button color="text">Contact Us</Button>
      </div>

      <WaitlistFormView handleViews={handleViews} id={id} />

      <span id="custom-components" />
    </Col>
  );
};

export default WaitList;
