import React, { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../../app/config/store';
import { logout } from '../../../../../app/shared/reducers/authentication';
import { analyticsTrack } from '../../shared/services/analytics';
import { hideHeader, showHeader } from '../../../../../app/shared/layout/header/header.reducer';

export const Logout = () => {
  const logoutUrl = useAppSelector(state => state.authentication.logoutUrl);
  const dispatch = useAppDispatch();

  useEffect(() => {
    console.log('logoutUrl INN', logoutUrl);

    dispatch(logout());
    if (logoutUrl) {
      localStorage.removeItem('loggedin');
      analyticsTrack('End Session');
      window.location.href = logoutUrl;
    }
  }, [logoutUrl]);

  useEffect(() => {
    dispatch(hideHeader()); // Hide header when this component is mounted
    return () => {
      dispatch(showHeader()); // Show header again when leaving this route
    };
  }, [dispatch]);

  // useLayoutEffect(() => {
  //   console.log("logoutUrl INN", logoutUrl);

  //   dispatch(logout());
  //   if (logoutUrl) {
  //     window.location.href = logoutUrl;
  //   }
  // });
  return (
    <></>
    // <div className="p-5">
    //   <h4>Logged out successfully!</h4>
    // </div>
  );
};

export default Logout;
