import aIChat from '../entities/ai-chat/ai-chat.reducer';
import generatedMessage from '../../../../app/entities/generated-message/generated-message.reducer';
import userPrompt from '../../../../app/entities/user-prompt/user-prompt.reducer';
import sourceDocument from '../../../../app/entities/source-document/source-document.reducer';
import sourceDocumentTextSegment from '../../../../app/entities/source-document-text-segment/source-document-text-segment.reducer';
import fileUpload from '../../../../app/entities/file-upload/file-upload.reducer';
import userCreditsBalance from '../../../../app/entities/user-credits-balance/user-credits-balance.reducer';
import userCreditsDeposit from '../../../../app/entities/user-credits-deposit/user-credits-deposit.reducer';
import semanticSearchEntity from '../../../../app/entities/semantic-search-entity/semantic-search-entity.reducer';
import knolliAIUserProfile from '../../../../app/entities/knolli-ai-user-profile/knolli-ai-user-profile.reducer';
import aiAssitant from '../entities/ai-assitant/ai-assitant.reducer';
import header from '../shared/layout/header/header.reducer';
import userGroup from '../entities/user-group/user-group.reducer';
import group from '../entities/group/group.reducer';
import knolliAIUserProfileField from '../entities/knolli-ai-user-profile-field/knolli-ai-user-profile-field.reducer';
import knolliAIUserProfileFieldValue from '../../../../app/entities/knolli-ai-user-profile-field-value/knolli-ai-user-profile-field-value.reducer';
import userProfile from '../../../../app/entities/user-profile/user-profile.reducer';
import userProfileFieldValue from '../entities/user-profile-field-value/user-profile-field-value.reducer';
import userProfileField from '../../../../app/entities/user-profile-field/user-profile-field.reducer';
import userIntent from '../../../../app/entities/user-intent/user-intent.reducer';
import inviteUserRequest from '../../../../app/entities/invite-user-request/invite-user-request.reducer';
import userSubscription from '../../../../app/entities/user-subscription/user-subscription.reducer';
import subscriptionPlan from '../../../../app/entities/subscription-plan/subscription-plan.reducer';
import waitList from '../entities/wait-list/wait-list.reducer';
import chatDashboard from '../../../../app/entities/chat-dashboard/chat-dashboard.reducer';
/* jhipster-needle-add-reducer-import - JHipster will add reducer here */

const entitiesReducers = {
  aIChat,
  generatedMessage,
  userPrompt,
  sourceDocument,
  sourceDocumentTextSegment,
  fileUpload,
  userCreditsBalance,
  userCreditsDeposit,
  semanticSearchEntity,
  knolliAIUserProfile,
  aiAssitant,

  chatDashboard,
  header,
  userGroup,
  group,
  knolliAIUserProfileField,
  knolliAIUserProfileFieldValue,
  userProfile,
  userProfileFieldValue,
  userProfileField,
  userIntent,
  inviteUserRequest,
  userSubscription,
  subscriptionPlan,
  waitList,
  /* jhipster-needle-add-reducer-combine - JHipster will add reducer here */
};

export default entitiesReducers;
