import dayjs from 'dayjs';
import { IUser } from 'app/shared/model/user.model';
import { CreditsType } from 'app/shared/model/enumerations/credits-type.model';

export interface IUserCreditsDeposit {
  id?: number;
  type?: keyof typeof CreditsType | null;
  amount?: number | null;
  balanceUpdateTimestamp?: dayjs.Dayjs | null;
  description?: string | null;
  user?: IUser | null;
}

export const defaultValue: Readonly<IUserCreditsDeposit> = {};
