// src/store/headerSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface HeaderState {
  isVisible: boolean;
}

const initialState: HeaderState = {
  isVisible: true,
};

const headerSlice = createSlice({
  name: 'header',
  initialState,
  reducers: {
    showHeader: state => {
      state.isVisible = true;
    },
    hideHeader: state => {
      state.isVisible = false;
    },
    setHeaderVisibility: (state, action: PayloadAction<boolean>) => {
      state.isVisible = action.payload;
    },
  },
});

export const { showHeader, hideHeader, setHeaderVisibility } = headerSlice.actions;

export default headerSlice.reducer;
