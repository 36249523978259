import { useAppDispatch, useAppSelector } from '../../../../../app/config/store';
import React, { useEffect, useRef, useState } from 'react';
import { fetchEventSource } from '@microsoft/fetch-event-source';

import { setShowTemplates, setUserPromptFullText } from '../../../../../app/entities/chat-dashboard/chat-dashboard.reducer';

import { Button, Input } from 'reactstrap';

import { toast } from 'react-toastify';
import { SendIcon } from '../../shared/icon-svg/icon';
import { getEntities } from '../../entities/ai-chat/ai-chat.reducer';
import { overridePaginationStateWithQueryParams } from '../../../../../app/shared/util/entity-utils';
import { getPaginationState } from 'react-jhipster';
import { ITEMS_PER_PAGE } from '../../../../../app/shared/util/pagination.constants';
import { useLocation, useParams } from 'react-router';

export const ChatBot = props => {
  const userPromptRef = useRef(null);
  const dispatch = useAppDispatch();
  const pageLocation = useLocation();
  const id = useParams<'id'>().id;
  const [search, setsearch] = useState();
  const [error, setError] = useState('');
  const [disablePromptSubmit, setDisablePromptSubmit] = useState(true);
  const userPromptFullText = useAppSelector(state => state.chatDashboard.userPromptFullText);

  const selectedPrompt = useAppSelector(state => state.chatDashboard.selectedPrompt);
  const [userPromptText, setUserPromptText] = useState('');
  const [userNameInital, setUserNameInital] = useState('');
  const [userName, setUserName] = useState('');
  const userAccount = useAppSelector(state => state.authentication.account);
  const { assistantId, messageCountCurrent } = props;

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getPaginationState(pageLocation, ITEMS_PER_PAGE, 'id'), pageLocation.search),
  );

  const configUserChars = userAccount => {
    if (userAccount.firstName != null && userAccount.firstName != undefined && userAccount.firstName != '') {
      setUserName(userAccount.firstName);
      setUserNameInital(userAccount.firstName.charAt(0));
    } else {
      setUserName('User');
      setUserNameInital(userAccount?.email.charAt(0));
    }
  };

  useEffect(() => {
    configUserChars(userAccount);
  }, []);

  useEffect(() => {
    if (props.userConversationId && userPromptText != '') {
      props.setUserConversation(props.userConversation + userPromptFullText);
      props.setLoading(true);
      props.setShowLoading(true);
      let data = {
        prompt: userPromptText,
        aiChatId: props.userConversationId,
      };
      // Clear userPromptText before making the API call
      setUserPromptText('');
      getChatGptResponse(data);
    }
  }, [props.userConversationId]);

  useEffect(() => {
    if (userPromptRef.current && selectedPrompt) {
      userPromptRef.current.value = selectedPrompt;
      const scrollHeight = userPromptRef.current.scrollHeight;
      userPromptRef.current.style.height = scrollHeight + 'px';
      setDisablePromptSubmit(false);
    }
  }, [search]);

  useEffect(() => {
    if (userPromptRef.current && (search || search == '')) {
      userPromptRef.current.value = search;
      // Reset height to 'auto' first to correctly calculate scrollHeight
      userPromptRef.current.style.height = 'auto';
      // Dynamically set the height based on scrollHeight
      const scrollHeight = userPromptRef.current.scrollHeight;
      userPromptRef.current.style.height = `${scrollHeight}px`;
      // Enable the submit button
      setDisablePromptSubmit(false);
    }
  }, [search]);

  const onSubmit = async e => {
    const prompt = userPromptRef.current.value.trim();
    // Save prompt and chatId to Local storage
    // let aiChatId = props.userOldConversationId ? props.userOldConversationId : props.userConversationId;
    let aiChatId = props.userConversationId;
    let chatData = { currentChatId: aiChatId, currentPrompt: prompt };
    localStorage.setItem('chatData', JSON.stringify(chatData));

    if (!prompt) {
      setError('Please enter message.');
      return;
    }
    setError('');

    if (userPromptRef.current) {
      userPromptRef.current.value = null;
      userPromptRef.current.style.height = '48px';
    }

    let userCompleteText =
      '<div class="user-text-block">' +
      '<div class="usertext"><strong><span>' +
      userNameInital +
      '</span></strong>' +
      '<div class="user-text-box">' +
      prompt +
      '</div> </div>' +
      '</div>' +
      '<div class="ai-Text"><strong><span class="ai_icon"><img src="content/images/knolli-icon.svg" alt="Logo"></span></strong></div>';
    setUserPromptText(prompt);
    dispatch(setUserPromptFullText(userCompleteText));
    dispatch(setShowTemplates(false));
    setDisablePromptSubmit(true);

    if (props.userOldConversationId || props.userConversationId) {
      props.setUserConversation(props.userConversation + userCompleteText);
      props.setLoading(true);
      props.setShowLoading(true);
      let data = {
        prompt: prompt,
        aiChatId: props.userConversationId,
      };
      getChatGptResponse(data);
    } else {
      props.newConvMethod();
    }
  };

  const getChatGptResponse = async data => {
    const url = `api/ai-chat`;

    props.setGeneratingChatGptConversationId(data.aiChatId);

    let chatGptFinalResp = '';
    await fetchEventSource(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-XSRF-TOKEN': getCookie('XSRF-TOKEN')?.toString(),
        Accept: 'text/event-stream',
      },
      body: JSON.stringify({
        chatId: data.aiChatId,
        aiAssistantId: assistantId,
        prompt: data.prompt,
      }),
      openWhenHidden: true,
      async onopen(res) {
        if (res.status == 422) {
          props.setUserConversation('');
        }

        if (res.status == 500) {
          toast.error('AI had some technical error, please try again.');
        }
        chatGptFinalResp = '';
      },
      onmessage(event) {
        chatGptFinalResp = event.data;
        props.handleConversation(event?.data);
      },
      onclose() {
        setUserPromptText('');
        props.setLoading(false);
        props.setGeneratingChatGptConversationId('');
        props.reloadEntitiesOnStreamingEnd();
        props.updateConversation(chatGptFinalResp);
        props.handleConversation(false);
        localStorage.setItem('chatData', JSON.stringify({ currentChatId: '', currentPrompt: '' }));
        // dispatch(setSelectedPrompt(''));
      },
      onerror(err) {},
    });
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}&assistantId=${id}`,
        // query: `assistantId.equals=${id}`,
      }),
    );
  };

  const getCookie = name => {
    const value = `; ${document.cookie}`;
    const parts = value?.split(`; ${name}=`);
    if (parts.length === 2) return parts?.pop().split(';')?.shift();
  };

  const handleSearchdata = async value => {
    setsearch(value);
  };

  const handleKeyPress = async e => {
    if (e.key === 'Enter') {
      await onSubmit(search);
    }
  };

  return (
    <div className="search-section">
      <div className="mt-3">
        <p className="mb-2" style={{ fontSize: '13px', float: 'right' }}>
          {messageCountCurrent} message(s) left.
        </p>
      </div>
      <div className="search-wrap mt-0">
        <div className="search-position position-relative">
          <Input
            key="search-input"
            innerRef={userPromptRef}
            onChange={e => handleSearchdata(e.target.value)}
            onKeyDown={e => {
              if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault();
                handleKeyPress(e);
              }
            }}
            type="textarea"
            id="userPrompt"
            rows="1"
            className="float-left msg-design"
            placeholder={!assistantId ? 'Please select the assistant' : 'Search or take a note'}
            disabled={!assistantId || messageCountCurrent == 0}
          />
          <Button
            color="success"
            id="save-entity"
            type="submit"
            outline
            onClick={e => onSubmit(search)}
            className="ms-2 float-right submit-prompt-btn"
            disabled={props.loading}
          >
            <SendIcon />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ChatBot;
function dispatch(arg0: unknown) {
  throw new Error('Function not implemented.');
}
