import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Row, Col, Tooltip } from 'reactstrap';
import { getPaginationState, ValidatedField, ValidatedForm } from 'react-jhipster';
import { overridePaginationStateWithQueryParams } from '../../../../../app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from '../../../../../app/config/store';
import { ITEMS_PER_PAGE } from '../../../../../app/shared/util/pagination.constants';
import { inviteUser } from '../../entities/ai-chat/ai-chat.reducer';
import { toast } from 'react-toastify';

import { EmailIcon, DeleteIcon, AddIcon } from '../../shared/icon-svg/icon';
import { reset } from '../../entities/user-group/user-group.reducer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

export const UserGroupForm = props => {
  const dispatch = useAppDispatch();
  const { id } = useParams<'id'>();
  const isNew = id === undefined;
  const { idArray } = props;

  const userGroupEntity = useAppSelector(state => state.userGroup.entity);
  const loading = useAppSelector(state => state.userGroup.loading);
  const updateSuccess = useAppSelector(state => state.userGroup.updateSuccess);
  const groupList = useAppSelector(state => state.group.entities);
  const assistant = useAppSelector(state => state.aiAssitant.entity);
  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getPaginationState(location, ITEMS_PER_PAGE, 'id'), location.search),
  );
  const [userIdArray, setUserIdArray] = useState(idArray);
  const [fields, setFields] = useState([{ id: Date.now() }]);

  const addField = () => {
    setFields([...fields, { id: Date.now() }]);
  };

  const handleFieldChange = (id, key, value) => {
    // Update the specific field by ID and key
    const updatedFields = fields.map(f => {
      if (f.id === id) {
        return { ...f, [key]: value };
      }
      return f;
    });
    setFields(updatedFields); // Ensure this updates your state
  };

  const removeField = (fieldId: number) => {
    setFields(fields.filter(field => field.id !== fieldId));
  };

  // const groupsListUser = groupList
  //   .filter(group => group.groupType === 'USER')
  //   .map(group => ({
  //     value: group.groupId,
  //     label: group.name.replace('User Group', ''),
  //   }));

  // console.log("groupListUser", groupsListUser);

  // console.log("groupList", groupList);

  const handleClose = () => {
    // navigate(`/product/${productId}/app/${appId}`);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    }
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  useEffect(() => {
    setUserIdArray(idArray);
  }, [idArray]);

  const saveEntity = values => {
    fields.map(field => {
      dispatch(
        inviteUser({
          inviteUserEmail: field.email,
          groupId: field.group,
        }),
      );
    });
    setFields([{ id: Date.now() }]);
    toast('Invitation sent successfully.');
  };

  // console.log('productEntity', productEntity);

  const defaultValues = () =>
    isNew
      ? {}
      : {
          ...userGroupEntity,
          user: userGroupEntity?.user?.id,
          team: userGroupEntity?.team?.id,
        };

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <div>
      <Row className="" style={{ justifyContent: 'space-between' }}>
        <Col md="4">
          <h3>Invite team members</h3>
          <p>
            You can invite a new member(s) to <b>{assistant.qdrantCollectionName}</b>.{' '}
            <FontAwesomeIcon icon={faInfoCircle} id="roles-info-invite">
              {' '}
            </FontAwesomeIcon>
          </p>
          <Tooltip
            {...{
              autohide: true,
              flip: true,
            }}
            isOpen={tooltipOpen}
            target="roles-info-invite"
            toggle={toggle}
          >
            <p>
              Admin: Allows to team member use chat and manage/config as well for <b>{assistant.qdrantCollectionName}</b>
            </p>
            <p>
              User: Allows team member to only use chat for <b>{assistant.qdrantCollectionName}</b>
            </p>
          </Tooltip>
        </Col>
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {fields.map((field, index) => (
                <Row key={field.id} className="mb-3">
                  <Col xs="6">
                    <ValidatedField
                      id={`user-group-email-${field.id}`}
                      name={`email-${field.id}`}
                      data-cy="email"
                      type="email"
                      placeholder="team@team.com"
                      required
                      value={field.email || ''} // Ensure the field has a value
                      onChange={e => handleFieldChange(field.id, 'email', e.target.value)} // Handle change
                    />
                  </Col>
                  <Col xs="5">
                    <ValidatedField
                      className="col-12"
                      id={`user-group-user-${field.id}`}
                      name={`group-${field.id}`}
                      data-cy="group"
                      type="select"
                      required
                      value={field.group || ''} // Ensure the field has a value
                      onChange={e => handleFieldChange(field.id, 'group', e.target.value)} // Handle change
                    >
                      <option value="" key="">
                        Select
                      </option>
                      {groupList.map(item => (
                        <option value={item.groupId} key={item.groupType}>
                          {item.groupType}
                        </option>
                      ))}
                    </ValidatedField>
                  </Col>
                  <Col xs="1" className="ps-0">
                    {index >= 1 && (
                      <Button className="px-0" color="save" onClick={() => removeField(field.id)}>
                        <DeleteIcon />
                      </Button>
                    )}
                  </Col>
                </Row>
              ))}
              <Col xs="11" className="d-flex gap-2 justify-content-between align-items-center">
                <Button color="text" type="button" className="btn btn-link" onClick={addField}>
                  <AddIcon /> Add Another
                </Button>
                <Button
                  color="text"
                  className="btn btn-link d-flex gap-2 align-items-center"
                  id="save-entity"
                  data-cy="entityCreateSaveButton"
                  type="submit"
                >
                  <EmailIcon /> Send Invites
                </Button>
              </Col>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default UserGroupForm;
