import { IGroup } from 'app/shared/model/group.model';
import { IUser } from 'app/shared/model/user.model';
import { IAiAssitant } from 'app/shared/model/ai-assitant.model';
import { InviteStatus } from 'app/shared/model/enumerations/invite-status.model';

export interface IInviteUserRequest {
  id?: number;
  status?: keyof typeof InviteStatus | null;
  token?: string | null;
  email?: string | null;
  group?: IGroup | null;
  user?: IUser | null;
  aiAssitant?: IAiAssitant | null;
}

export const defaultValue: Readonly<IInviteUserRequest> = {};
