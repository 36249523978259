import dayjs from 'dayjs';
import { IUser } from 'app/shared/model/user.model';
import { IAiAssitant } from 'app/shared/model/ai-assitant.model';
import { IGroup } from 'app/shared/model/group.model';
import { FileLocationType } from 'app/shared/model/enumerations/file-location-type.model';
import { FileUseType } from 'app/shared/model/enumerations/file-use-type.model';

export interface IFileUpload {
  id?: number;
  fileName?: string | null;
  fileLocationType?: keyof typeof FileLocationType | null;
  fileLocation?: string | null;
  sourceLocation?: string | null;
  title?: string | null;
  createdDate?: dayjs.Dayjs | null;
  updatedDate?: dayjs.Dayjs | null;
  deletedDate?: dayjs.Dayjs | null;
  fileUseType?: keyof typeof FileUseType | null;
  user?: IUser | null;
  aiAssitant?: IAiAssitant | null;
  group?: IGroup | null;
}

export const defaultValue: Readonly<IFileUpload> = {};
