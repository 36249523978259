import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';
import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, serializeAxiosError, createChatSlice, ChatState } from '../../../app/shared/reducers/reducer.utils';

import { IAIChat, defaultValue } from 'app/shared/model/ai-chat.model';
import { toast } from 'react-toastify';
const initialState: ChatState<IAIChat> = {
  loading: false,
  errorMessage: null,
  entities: [],
  generatingMessage: false,
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
  totalItems: 0,
  aiChatResponse: null, // Add this line
  messageCount: 0,
  totalUsers: 0,
  users: [] as ReadonlyArray<IUser>,
  errorMessageUser: null,
  topTenQueriesUsers: [],
  errorMessageTopQueriesUser: null,
  statistics: [],
};

interface uploadFile {
  file: string | null;
}

export interface IUser {
  id?: any;
  login?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  activated?: boolean;
  langKey?: string;
  authorities?: any[];
  createdBy?: string;
  createdDate?: Date | null;
  lastModifiedBy?: string;
  lastModifiedDate?: Date | null;
  password?: string;
}

type Statistics = {
  statistics?: any;
  startDate?: any;
  endDate?: any;
  userId?: any;
  aiAssistantId?: any;
};

const apiUrl = 'api/ai-chats';
const apiUrlOne = 'api/ai-chat';
const customURL = 'api/custom';
const userChatApiUrl = 'api/user-chat';
const searchAiChatApiUrl = 'api/search-conversation';
const messageCountApiUrl = '/api/user-blc';
const userUrl = 'api/users';
const topTenQueryUserUrl = '/api/top-users';
const inviteUserApi = 'api/invite-team-member';
const getUsersListApi = 'api/users-details';
const resetPasswordApi = 'api/account-management/reset-password';
const chatHistoryUrl = 'api/by-assistant';
// Actions

export const getEntities = createAsyncThunk(
  'aiChat/fetch_entity_list',
  async ({ page, size, sort, query }: IQueryParams) => {
    const requestUrl = `${chatHistoryUrl}?${sort ? `page=${page || 0}&size=${size || 100}&sort=${sort || 'id'}&` : ''}cacheBuster=${new Date().getTime()}${
      query ? `&${query}` : ''
    }`;
    return axios.get<IAIChat[]>(requestUrl);
  },
  { serializeError: serializeAxiosError },
);

export const getEntity = createAsyncThunk(
  'aiChat/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IAIChat>(requestUrl);
  },
  { serializeError: serializeAxiosError },
);

export const getSingleChat = createAsyncThunk(
  'aiChat/fetch_single_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrlOne}/${id}`;
    return axios.get<IAIChat>(requestUrl);
  },
  { serializeError: serializeAxiosError },
);

export const createEntity = createAsyncThunk(
  'aiChat/create_entity',
  async (entity: IAIChat, thunkAPI) => {
    const result = await axios.post<IAIChat>(apiUrl, cleanEntity(entity));
    // thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const uploadFile = createAsyncThunk(
  'aiChat/create_entity',
  async (entity: uploadFile, thunkAPI) => {
    // const result = await axios.post<uploadFile>("api/upload-file", cleanEntity(entity));
    const result = await axios.post<uploadFile>('api/upload-file', cleanEntity(entity), {
      headers: { 'content-type': 'multipart/form-data' },
    });

    // thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const getRemainingMessagesLimit = createAsyncThunk(
  'aiChat/message-count',
  async () => {
    const requestUrl = `${messageCountApiUrl}`;
    return await axios.get<IAIChat>(requestUrl);
  },
  { serializeError: serializeAxiosError },
);

export const updateEntity = createAsyncThunk(
  'aiChat/update_entity',
  async (entity: IAIChat, thunkAPI) => {
    const result = await axios.put<IAIChat>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const partialUpdateEntity = createAsyncThunk(
  'aiChat/partial_update_entity',
  async (entity: IAIChat, thunkAPI) => {
    const result = await axios.patch<IAIChat>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const deleteEntity = createAsyncThunk(
  'aiChat/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<IAIChat>(requestUrl);
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const deleteAllMatches = createAsyncThunk(
  'delete_all_matches',
  async (_, thunkAPI) => {
    const requestUrl = `${customURL}/delete_all_matches`;
    const result = await axios.delete<IAIChat>(requestUrl);

    return result;
  },
  { serializeError: serializeAxiosError },
);

export const refreshMatches = createAsyncThunk(
  'refresh_all_matches',
  async (_, thunkAPI) => {
    const requestUrl = `${customURL}/refresh_all_matches`;
    const result = await axios.get<IAIChat>(requestUrl);

    return result;
  },
  { serializeError: serializeAxiosError },
);
// slice

export const sendUserPrompt = createAsyncThunk(
  'api/user-chat',
  async (entity: IAIChat) => {
    const result = await axios.post<IAIChat>(userChatApiUrl, cleanEntity(entity));
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const getAiChatBYSearchQuery = createAsyncThunk(
  'aiChat/search_entity',
  async (query: string) => {
    const requestUrl = `${searchAiChatApiUrl}/${query}`;
    return axios.get<IAIChat>(requestUrl);
  },
  { serializeError: serializeAxiosError },
);

export const getUsers = createAsyncThunk('userManagement/fetch_users', async ({ page, size, sort }: IQueryParams) => {
  const requestUrl = `${userUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  const result = await axios.get<IUser[]>(requestUrl);
  console.log('result', result);
  // console.log("payload", result.payload);

  return result;
});

export const getTopTenQueriesUser = createAsyncThunk(
  'aiChat/fetch_entity_list',
  async ({ page, size, sort, query }: IQueryParams) => {
    const requestUrl = `${topTenQueryUserUrl}?${sort ? `page=${page || 0}&size=${size || 100}&sort=${sort || 'id'}&` : ''}cacheBuster=${new Date().getTime()}`;
    return axios.get<IAIChat[]>(requestUrl);
  },
  { serializeError: serializeAxiosError },
);

export const fetchStatistics = createAsyncThunk(
  'statistics/fetch',
  async ({ statistics, startDate, endDate, aiAssistantId }: Statistics) => {
    const response = await axios.get(
      `/api/statistics?statistics=${statistics}&startDate=${startDate}&endDate=${endDate}&aiAssistantId=${aiAssistantId}`,
    );
    return response.data; // Return fetched data
  },
  { serializeError: serializeAxiosError },
);

export const inviteUser = createAsyncThunk(
  'aiChat/invite_user_entity',
  async (entity: IAIChat, thunkAPI) => {
    const result = await axios.post<IAIChat>(inviteUserApi, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const getUsersList = createAsyncThunk('userManagement/fetch_users_list', async ({ page, size, sort }: IQueryParams) => {
  const requestUrl = `${getUsersListApi}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  const result = await axios.get<IUser[]>(requestUrl);
  console.log('result', result);
  // console.log("payload", result.payload);

  return result;
});

export const resetPassword = createAsyncThunk(
  'aiChat/reset_password',
  async (entity: IAIChat, thunkAPI) => {
    const result = await axios.put<IAIChat>(resetPasswordApi, cleanEntity({}));
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const AiChatSlice = createChatSlice({
  name: 'aiChat',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addCase(getUsers.pending, state => state)
      .addCase(getUsers.rejected, (state, action) => {
        state.errorMessageUser = action.error.message;
      })
      .addCase(getUsersList.fulfilled, (state, action) => {
        state.users = action.payload.data;
      })
      .addCase(getUsers.fulfilled, (state, action) => {
        state.users = action.payload.data;
      })
      .addCase(getTopTenQueriesUser.pending, state => state)
      .addCase(getTopTenQueriesUser.rejected, (state, action) => {
        state.errorMessageTopQueriesUser = action.error.message;
      })
      .addCase(getTopTenQueriesUser.fulfilled, (state, action) => {
        state.topTenQueriesUsers = action.payload.data;
      })
      // .addCase(getRemainingMessagesLimit.fulfilled, (state, action) => {
      //   state.messageCount = action.payload.data;
      // })

      // .addCase(fetchTopUsers.pending,(state,action) =>{

      // })
      // .addCase(fetchTopUsers.fulfilled,(state,action) =>{

      //      state.topUsers=action.payload
      // })

      .addCase(fetchStatistics.pending, state => {
        state.loading = true;
        state.errorMessage = null;
      })
      .addCase(fetchStatistics.fulfilled, (state, action) => {
        state.loading = false;
        state.statistics = action.payload || []; // Setting topUsers from fulfilled response
      })
      // .addCase(fetchTopUsers.rejected, (state, action) => {
      //   state.loading = false;
      //   state.errorMessage = action.payload || 'Failed to fetch top users';
      // })
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        const { data, headers } = action.payload;

        return {
          ...state,
          loading: false,
          entities: data,
          totalItems: parseInt(headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isFulfilled(sendUserPrompt), (state, action) => {
        state.updateSuccess = true;
        state.generatingMessage = false;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(getEntities, getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(sendUserPrompt), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.generatingMessage = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      })
      .addMatcher(isFulfilled(getUsers), (state, action) => {
        const { data, headers } = action.payload;

        return {
          ...state,
          users: data,
          totalUsers: parseInt(headers['x-total-count'], 10),
        };
      });
  },
});

export const { reset, updateMessageCount } = AiChatSlice.actions;

// Reducer
export default AiChatSlice.reducer;
