import { IUser } from 'app/shared/model/user.model';

export interface IUserSubscription {
  id?: number;
  name?: string | null;
  amountPaid?: number | null;
  active?: boolean | null;
  stripeCustomerId?: string | null;
  startDate?: number | null;
  endDate?: number | null;
  duration?: string | null;
  stripeSubscriptionId?: string | null;
  stripePriceId?: string | null;
  stripeSubscriptionStatus?: string | null;
  user?: IUser | null;
}

export const defaultValue: Readonly<IUserSubscription> = {
  active: false,
};
