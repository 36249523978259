import '../../content/css/chatbot-layout.scss';
import '../../content/css/common.css';
import React, { useEffect, useRef, useState } from 'react';
import { Row, Col, Button, FormGroup, Label, Input, Card, CardHeader, CardBody, Spinner, Tooltip } from 'reactstrap';
import { useAppDispatch, useAppSelector } from '../../../../../app/config/store';
import { resetPassword } from '../../entities/ai-chat/ai-chat.reducer';
interface Props {
  cardCols?: string;
}
import { UserProfileFieldType } from '../../../../../app/shared/model/enumerations/user-profile-field-type.model';
import { ValidatedField, ValidatedForm } from 'react-jhipster';
import { getAiAssistants } from '../../entities/ai-assitant/ai-assitant.reducer';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

import { updateAccount, uploadProfileImage, getProfilePic } from '../../shared/reducers/authentication';
import Sidebar from '../sidebar/sidebar';
import { hideHeader, showHeader } from '../../shared/layout/header/header.reducer';
// import { UploadIcon } from '../../../../../app/shared/icon-svg/icon';
import { UploadIcon } from '../../shared/icon-svg/icon';
import { toast } from 'react-toastify';
// import { getEntities } from '../../entities/knolli-ai-user-profile-field/knolli-ai-user-profile-field.reducer';
import { updateEntity, getEntities, reset } from '../../entities/user-profile-field-value/user-profile-field-value.reducer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export const AccountSettings = (props: Props) => {
  const dispatch = useAppDispatch();

  const [selectedFile, setSelectedFile] = useState(null);
  const account = useAppSelector(state => state.authentication.account);
  const [accountDetails, setAccountDetails] = useState();
  const [image, setImage] = useState<any>();
  const submitRef = useRef(null);
  const profileFieldsList = useAppSelector(state => state.userProfileFieldValue.entities?.data?.fields);
  const [userProfileFieldsList, setUserProfileFieldsList] = useState([]);
  const updating = useAppSelector(state => state.userProfileFieldValue.updating);
  const loadingAccount = useAppSelector(state => state.authentication.loading);
  const loading = useAppSelector(state => state.userProfileFieldValue.loading);
  const aiAssitantList = useAppSelector(state => state.aiAssitant.entities.data);
  const [tooltipOpen, setTooltipOpen] = useState(null);
  const toggleToolTip = index => setTooltipOpen(tooltipOpen === index ? null : index);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(prevState => !prevState);

  const getProfileImage = async () => {
    const { payload } = await dispatch(getProfilePic());
    setImage(payload?.data?.data);
  };

  const handleFileChange = async event => {
    setSelectedFile(event.target.files[0]);
    const entity = {
      file: event.target.files[0],
      fileUseType: 'GENERAL_PURPOSE',
    };
    await dispatch(uploadProfileImage(entity));
    getProfileImage();
  };

  useEffect(() => {
    dispatch(getAiAssistants({}));
    dispatch(reset());
  }, []);

  useEffect(() => {
    setUserProfileFieldsList(profileFieldsList);
  }, [profileFieldsList]);

  useEffect(() => {
    getProfileImage();
    dispatch(hideHeader()); // Hide header when this component is mounted
    return () => {
      dispatch(showHeader()); // Show header again when leaving this route
    };
  }, [dispatch]);

  useEffect(() => {
    if (account)
      setAccountDetails({
        firstName: account.firstName,
        lastName: account.lastName,
        // emailAddress: account.email,
      });
  }, [account]);

  // console.log("accountDetails", accountDetails);

  const defaultValues = {
    firstName: accountDetails?.firstName,
    lastName: accountDetails?.lastName,
    email: account?.email,
    username: account?.login,
  };

  const saveEntity = async values => {
    // console.log("values", values);

    const entity = {
      ...accountDetails,
      // ...values
    };
    // console.log("entity @@@@", entity);

    await dispatch(updateAccount(entity));
  };

  const resetAccountPassword = () => {
    dispatch(resetPassword());
    toast('Password Reset Email sent.');
  };
  const handleProfileFieldsValues = (event, id) => {
    const { name, value } = event.target;

    let updatedValues = userProfileFieldsList.map(field => (field.fieldId === id ? { ...field, value: value } : field));
    setUserProfileFieldsList(updatedValues);
  };
  const submitProfileFieldsValues = async () => {
    if (userProfileFieldsList.length > 0) {
      const updatedFields = userProfileFieldsList?.filter(field => field?.value || field?.value == '');
      await dispatch(updateEntity({ fields: updatedFields }));
    }
  };
  const handleAssistant = async ({ target }) => {
    let { value } = target;
    if (value != '') {
      await dispatch(getEntities({ query: `aiAssistantId=${value}` }));
    } else {
      setUserProfileFieldsList([]);
    }
  };

  const getFieldInputType = (fieldType: string): string => {
    switch (fieldType) {
      case UserProfileFieldType.String:
        return 'text';
      case UserProfileFieldType.Text:
        return 'textarea';
      case UserProfileFieldType.Date:
        return 'date';
      case UserProfileFieldType.Number:
        return 'number';
      default:
        return 'text';
    }
  };

  // console.log("account", account);

  return (
    <div className="dashboard-app-p overflow-hidden">
      <Sidebar />

      <div className={'is_open p-3 d-flex flex-column'}>
        <Row className="justify-content-start">
          <Col md="8">
            <h2 className="main-title" id="profileUpdateHeading">
              Account Details
            </h2>
          </Col>
        </Row>
        <Row className="justify-content-start mt-2">
          <Col className="heading-sec" md="6">
            <span>Update your photo and personal details here.</span>
          </Col>
          <Col md="6" className="d-flex gap-3 align-items-center justify-content-end">
            <Button className="theme-btn bg-white border-white" color="white">
              Cancel
            </Button>
            <Button className="theme-btn" ref={submitRef} onClick={resetAccountPassword}>
              Reset Password
            </Button>
            {/* <Button className="theme-btn" ref={submitRef} onClick={saveEntity}>
              Save
            </Button> */}
          </Col>
        </Row>
        <Row className="mt-4">
          <Col md="8" xl="9">
            <Card className="p-0 card-view">
              <CardHeader className="bg-white">Personal information</CardHeader>
              <CardBody>
                <ValidatedForm
                  className="row row-cols-2"
                  onSubmit={() => {
                    submitRef.current.click();
                  }}
                  defaultValues={defaultValues}
                >
                  <ValidatedField
                    label="First Name"
                    id="profile-firstName"
                    name="firstName"
                    data-cy="firstName"
                    type="text"
                    required
                    onChange={e => setAccountDetails({ ...accountDetails, firstName: e.target.value })}
                  />
                  <ValidatedField
                    label="Last Name"
                    id="profile-lastName"
                    name="lastName"
                    data-cy="lastName"
                    type="text"
                    required
                    onChange={e => setAccountDetails({ ...accountDetails, lastName: e.target.value })}
                  />
                  <ValidatedField label="Email Address" id="profile-email" disabled name="email" data-cy="email" type="email" required />
                  <ValidatedField label="Username" id="profile-username" disabled name="username" data-cy="username" type="text" required />
                  <ValidatedField label="Timezone" id="profile-timezone" disabled name="timezone" data-cy="timezone" type="text" required />
                </ValidatedForm>
                <div className="d-flex align-items-center justify-content-end">
                  {loadingAccount ? (
                    <Spinner color="primary" className="float-end" />
                  ) : (
                    <Button className="theme-btn" id="save-entity" ref={submitRef} onClick={saveEntity}>
                      Update
                    </Button>
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>

          <Col md="4" xl="3">
            <FormGroup className="card p-0 shadow-sm border-0">
              <CardHeader className="bg-white p-3">
                <Label className="mb-0"> Your Photo </Label>
              </CardHeader>
              <CardBody>
                {image?.fileLocation != undefined ? (
                  <div className="upload-wrapper">
                    {/* <img src='../../../../../../webapp/content/images/knolli-logo-img.png' alt='img' /> */}
                    <img src={image.fileLocation} alt="img" />
                  </div>
                ) : (
                  <Label for="profile-photo" className="upload-wrapper">
                    <Input type="file" id="profile-photo" name="photo" onChange={handleFileChange} />
                    {selectedFile && <p>Selected file: {selectedFile?.name}</p>}
                    <h5 className="text-center">
                      <span className="d-block text-center mb-4">
                        <UploadIcon />
                      </span>
                      Click to upload or drag and drop SVG, PNG, JPG or GIF
                      <span className="d-block">(max, 800x400px)</span>
                    </h5>
                  </Label>
                )}
              </CardBody>
            </FormGroup>
            {/* <Card className="d-flex gap-2 flex-wrap mt-4 shadow-sm border-0">
              <Button color="secondary" className="mt-2" onClick={() => setSelectedFile(null)}>
                Edit Your Photo
              </Button>
              <Button color="danger" className="mt-2">
                Google Connect
              </Button>
            </Card> */}
          </Col>
        </Row>

        <Row className="mt-4">
          <Col md="8" xl="9">
            <Card className="p-0 card-view">
              <CardHeader className="bg-white ">
                Profile fields
                <Col md="6" className="float-end">
                  {' '}
                  <ValidatedField
                    id="ai-assitant-aiAssistant"
                    name="aiAssistant"
                    data-cy="aiAssistant"
                    type="select"
                    onChange={handleAssistant}
                  >
                    <option value="">Select Assistant</option>
                    {aiAssitantList &&
                      aiAssitantList?.length > 0 &&
                      aiAssitantList?.map(aiAssistant => (
                        <option value={aiAssistant?.assistantId} key={aiAssistant?.assistantId}>
                          {aiAssistant?.collectionName}
                        </option>
                      ))}
                  </ValidatedField>
                </Col>
              </CardHeader>

              <CardBody>
                {userProfileFieldsList && userProfileFieldsList.length > 0 ? (
                  <ValidatedForm className="row row-cols-2" onSubmit={submitProfileFieldsValues}>
                    <div className="mb-3">
                      {userProfileFieldsList &&
                        userProfileFieldsList.length > 0 &&
                        userProfileFieldsList.map((field, index) => (
                          <div className="row  align-items-center" key={index}>
                            <Label className="col-4 mb-3">
                              {' '}
                              {field?.name}{' '}
                              {field?.description && (
                                <>
                                  <FontAwesomeIcon
                                    icon={faInfoCircle}
                                    id={`profile-fields${index}`}
                                    style={{ cursor: 'pointer', color: '#00523f' }}
                                  />{' '}
                                  <Tooltip
                                    isOpen={tooltipOpen == index}
                                    target={`profile-fields${index}`}
                                    style={{ backgroundColor: '#00523f' }}
                                    toggle={() => toggleToolTip(index)}
                                  >
                                    {field?.description}
                                  </Tooltip>
                                </>
                              )}
                            </Label>
                            <ValidatedField
                              key={field?.fieldId}
                              className="col-8"
                              id={`profile-${field?.name}`}
                              name={`name-${field?.fieldId}`}
                              type={getFieldInputType(field?.type)}
                              value={field?.value}
                              onChange={event => handleProfileFieldsValues(event, field?.fieldId)}
                            />
                          </div>
                        ))}
                    </div>
                    <div className="col-md-12 ">
                      {loading ? (
                        <Spinner className="float-end" />
                      ) : (
                        <Button className="theme-btn float-end" id="save-entity" type="submit" disabled={updating}>
                          Update
                        </Button>
                      )}
                    </div>
                  </ValidatedForm>
                ) : loading ? (
                  <div className="d-flex justify-content-center">
                    <Spinner className="" />
                  </div>
                ) : (
                  <div className="d-flex justify-content-center">
                    <Label>No Profile Field found</Label>
                  </div>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default AccountSettings;
