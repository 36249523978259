import dayjs from 'dayjs';
import { IUser } from 'app/shared/model/user.model';
import { IAiAssitant } from 'app/shared/model/ai-assitant.model';
import { ConversationStatus } from 'app/shared/model/enumerations/conversation-status.model';

export interface IAIChat {
  id?: number;
  chatMemory?: string | null;
  title?: string | null;
  context?: string | null;
  favourite?: boolean | null;
  status?: keyof typeof ConversationStatus | null;
  createdDate?: dayjs.Dayjs | null;
  updatedDate?: dayjs.Dayjs | null;
  deletedDate?: dayjs.Dayjs | null;
  goal?: string | null;
  user?: IUser | null;
  aiAssitant?: IAiAssitant | null;
  topUsers?: any[] | undefined;
}

export const defaultValue: Readonly<IAIChat> = {
  favourite: false,
};
