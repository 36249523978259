import { getSingleChat } from '../../entities/ai-chat/ai-chat.reducer';

import { useAppDispatch, useAppSelector } from '../../../../../app/config/store';
import { overridePaginationStateWithQueryParams } from '../../../../../app/shared/util/entity-utils';
import { ITEMS_PER_PAGE } from '../../../../../app/shared/util/pagination.constants';
import React, { useEffect, useState, useRef } from 'react';
import { getPaginationState } from 'react-jhipster';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Navbar, Nav, NavItem, NavLink, Collapse } from 'reactstrap';
import { renderToString } from 'react-dom/server';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
// interface Props {}
export const AichatListView = props => {
  const dispatch = useAppDispatch();

  const { userConversationId, setGeneratedMessage } = props;
  const [airesponse, setAiresponse] = useState<any>();

  const [openCollapse, setOpenCollapse] = useState(0);
  const [userNameInital, setUserNameInital] = useState('');
  const [userName, setUserName] = useState('');
  const userAccount = useAppSelector(state => state.authentication.account);
  const configUserChars = userAccount => {
    if (userAccount.firstName != null && userAccount.firstName != undefined && userAccount.firstName != '') {
      setUserName(userAccount.firstName);
      setUserNameInital(userAccount.firstName.charAt(0));
    } else {
      setUserName('User');
      setUserNameInital(userAccount?.email.charAt(0));
    }
  };
  const messagesEndRef = useRef<HTMLDivElement>(null);
  // Function to scroll to the bottom
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
  };

  useEffect(() => {
    configUserChars(userAccount);
  }, []);

  useEffect(() => {
    if (airesponse != props.userConversationAI) {
      setAiresponse(props.userConversationAI);
      scrollToBottom();
    }
    if (props.userConversationAI == false) {
      setAiresponse(false);
    }
  }, [props.userConversationAI]);

  let dataList = useAppSelector(state => state.aIChat.entities);

  const handleNavClick = async data => {
    props.setUserConversationId(data.id);
    let response: any = await dispatch(getSingleChat(data.id));
    let chatStr = '';
    if (response) {
      let generatedMsgs = response.payload.data.data.generatedMessage;
      setGeneratedMessage(generatedMsgs);
      let generatedMessages = [...generatedMsgs].reverse();
      if (generatedMessages && generatedMessages.length > 0) {
        const lastElement = generatedMessages[generatedMessages.length - 1];
        const promtPersona = lastElement && lastElement.userPrompt && lastElement.userPrompt.promptPersona;
        if (promtPersona) {
          props.handleLastSelectedPersona(promtPersona);
        }
        generatedMessages.map(message => {
          let aiResp = message.aiMessage.includes('<!DOCTYPE html>')
            ? message.aiMessage
            : renderToString(
                <Markdown
                  remarkPlugins={[remarkGfm]}
                  components={{
                    a: ({ node, ...props }) => {
                      return (
                        <a {...props} target="_blank">
                          {props.children}
                        </a>
                      );
                    },
                  }}
                >
                  {message.aiMessage}
                </Markdown>,
              );

          chatStr +=
            '<div class="user-text-block">' +
            '<div class="usertext"><strong><span>' +
            userNameInital +
            '</span></strong>' +
            '<div class="user-text-box">' +
            (message.userPrompt ? message.userPrompt : ' ') +
            '</div> </div>' +
            '</div>' +
            '<div class="ai-Text"><strong><span class="ai_icon"><img src="content/images/knolli-icon.svg" alt="Logo"></span></strong></div>' +
            aiResp;
        });
        props.updateSelectedConversation(data.id, chatStr);
      } else {
        // props.handleLastSelectedPersona('');
        props.updateSelectedConversation(data.id, '');
      }
    }
  };

  // Function to group dates by month
  const groupAiChatListByMonth = () => {
    const months = [];
    dataList?.data?.aiChats?.forEach(chat => {
      const date = new Date(chat.createdDate);
      const currentYear = new Date().getFullYear();

      const createdYearName = Number(
        date.toLocaleString('en-US', {
          year: 'numeric',
        }),
      );

      // console.log('currentYear', typeof currentYear, 'createdYearName', typeof createdYearName);
      let monthName = '';
      if (currentYear == createdYearName) {
        monthName = date.toLocaleString('en-US', {
          month: 'long',
        });
      } else {
        monthName = date.toLocaleString('en-US', {
          month: 'long',
          year: 'numeric',
        });
      }

      let index = months.findIndex(item => item['month'] === monthName);
      if (index == -1) {
        months.push({ month: monthName, chats: [chat] });
      } else {
        months[index].chats.push(chat);
      }
    });
    return months;
  };

  const toggleCollapse = collapseId => {
    if (openCollapse === collapseId) {
      setOpenCollapse(null);
    } else {
      setOpenCollapse(collapseId);
    }
  };

  const groupedDataByMonths = groupAiChatListByMonth();

  return (
    <>
      {groupedDataByMonths.length > 0 && (
        <h6>
          <strong>Previous Conversations</strong>
        </h6>
      )}
      <Navbar className="sidedesign ">
        <Nav className="w-100" vertical>
          {groupedDataByMonths.map((data, idx) => {
            return (
              <div className="borderCollapse border-bottom-0">
                <a onClick={() => toggleCollapse(idx)} className={openCollapse === idx ? 'activeCollapse' : 'Collapseclose '}>
                  {data.month}
                </a>
                <Collapse isOpen={openCollapse === idx}>
                  {data.chats.map((product, i) => (
                    <NavItem>
                      <div className="d-flex justify-content-between">
                        <NavLink onClick={() => handleNavClick(product)} className={userConversationId == product.id ? 'active' : ''}>
                          {product.title ? product.title : 'New Chat'}{' '}
                        </NavLink>
                      </div>
                    </NavItem>
                  ))}
                </Collapse>
              </div>
            );
          })}
        </Nav>
      </Navbar>
    </>
  );
};

export default AichatListView;
