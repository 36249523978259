// PricingPlans.js
import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, CardBody, Button, Modal, ModalHeader, ModalBody, Badge, ButtonGroup } from 'reactstrap';

import SubscriptionForm from './SubscriptionForm';
import './Subscription-plan.scss';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { CheckSvgIcon, CrosSvgIcon } from '../../shared/icon-svg/icon';
import { useAppDispatch, useAppSelector } from '../../../../../app/config/store';
import Sidebar from '../sidebar/sidebar';
// import { getEntities as getParents } from '../../entities/parent/parent.reducer';

const stripeKey = 'pk_test_51QUkPmDQGa0VGTpkqhw0lWdBHAI8BcaiksmnhvQdweXZHlkLIoAyzfn3J2hcoqYzIy8y4aveBOzxX4vAaCcaMtDS00VzAbC374'; //String(process.env.STRIPE_PUBLISHABLE_KEY);
// Load the Stripe object
const stripePromise = loadStripe(stripeKey);
//console.log(stripeKey.slice(2, 32));

const PricingPlans = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const account = useAppSelector(state => state.authentication.account);
  const [plans, setPlans] = useState<any>([]);
  // const parents = useAppSelector(state => state.parent.paraents);
  const [myAccount, setMyAccount] = useState<any>();
  const dispatch = useAppDispatch();
  const [rSelected, setRSelected] = useState(1);
  const [monthlyPlans, setMonthlyPlans] = useState<any>([]);
  const [annualPlans, setAnnualPlans] = useState<any>([]);

  // useEffect(() => {
  //   dispatch(showHeader());
  //   return () => {
  //     dispatch(hideHeader());
  //   }
  // })

  useEffect(() => {
    if (account) {
      // dispatch(getParents({ sort: 'id', order: 'desc', size: 100, query: `userId.equals=${account?.id}` }));
    }
  }, [account]);

  // useEffect(() => {
  //   if (parents && parents.length > 0) {
  //     let myAccount = parents.find(x => x.user?.id === account?.id);

  //     setMyAccount(myAccount?.id);
  //   }
  // }, [parents]);

  const prices = [
    {
      plan_id: 'price_1QV9EKDQGa0VGTpkxEzwrOnB',
      product_id: 'prod_RNv34zmBxKbtR1',
      title: 'Premium',
      description: 'Develop a unique copilot experience.',
      price: 5388,
      currency: 'usd',
      interval: 'year',
      'interval Count': 1,
      order: 3,
      features: [
        '100 copilots',
        '10,000 messages/month',
        'Unlimited users',
        '10 admins',
        'Upload up to 20,000 files per copilot',
        'Live chat + email support',
        'Monetization',
        'Custom SSO',
        'Custom domain',
        'Custom branding',
        'Embed on website',
        'API Access',
        'Remove PII',
      ],
    },
    {
      plan_id: 'price_1QV9DUDQGa0VGTpkUNB106eF',
      product_id: 'prod_RNv34zmBxKbtR1',
      title: 'Premium',
      description: 'Develop a unique copilot experience.',
      price: 499,
      currency: 'usd',
      interval: 'month',
      'interval Count': 1,
      order: 3,
      features: [
        '100 copilots',
        '10,000 messages/month',
        'Unlimited users',
        '10 admins',
        'Upload up to 20,000 files per copilot',
        'Live chat + email support',
        'Monetization',
        'Custom SSO',
        'Custom domain',
        'Custom branding',
        'Embed on website',
        'API Access',
        'Remove PII',
      ],
    },
    {
      plan_id: 'price_1QV9B6DQGa0VGTpkoBrbfwla',
      product_id: 'prod_RNuz5Y1TIBba0g',
      title: 'Business',
      description: "Enhance your copilot's knowledge base.",
      price: 528,
      currency: 'usd',
      interval: 'year',
      'interval Count': 1,
      order: 2,
      features: [
        '10 copilots',
        '1,000 messages/month',
        'Unlimited users',
        '5 admins',
        'Upload up to 5,000 files per copilot',
        'AI chat + email support',
        'Monetization',
      ],
      popular: true,
    },
    {
      plan_id: 'price_1QV99hDQGa0VGTpkKLh286da',
      product_id: 'prod_RNuz5Y1TIBba0g',
      title: 'Business',
      description: "Enhance your copilot's knowledge base.",
      'Created (UTC)': '2024-12-12 09:58',
      price: 49,
      currency: 'usd',
      interval: 'month',
      'interval Count': 1,
      order: 2,
      features: [
        '10 copilots',
        '1,000 messages/month',
        'Unlimited users',
        '5 admins',
        'Upload up to 5,000 files per copilot',
        'AI chat + email support',
        'Monetization',
      ],
      popular: true,
    },
    {
      plan_id: 'price_1QV95zDQGa0VGTpk9nr0d8Hh',
      product_id: 'prod_RNuoLyonG27omS',
      title: 'Growth',
      description: 'Grow interactions with your copilot.',
      price: 204,
      currency: 'usd',
      interval: 'year',
      'interval Count': 1,
      order: 1,
      features: ['1 copilot', '250 messages/month', 'Unlimited users', '2 admins', 'Upload up to 100 files', 'AI chat + email support'],
    },
    {
      plan_id: 'price_1QV8zDDQGa0VGTpkfgK1p95l',
      product_id: 'prod_RNuoLyonG27omS',
      title: 'Growth',
      description: 'Grow interactions with your copilot.',
      price: 19,
      currency: 'usd',
      interval: 'month',
      'interval Count': 1,
      order: 1,
      features: ['1 copilot', '250 messages/month', 'Unlimited users', '2 admins', 'Upload up to 100 files', 'AI chat + email support'],
    },
  ];

  useEffect(() => {
    if (account) {
      setPlans(prices);
    }
  }, [myAccount]);

  useEffect(() => {
    if (plans && plans.length > 0) {
      setMonthlyPlans(plans.filter(plan => plan.interval == 'month').sort((a, b) => a.order - b.order));
      setAnnualPlans(plans.filter(plan => plan.interval == 'year').sort((a, b) => a.order - b.order));
    }
  }, [plans]);

  const toggleModal = (plan = null) => {
    setSelectedPlan(plan);
    setModalOpen(!modalOpen);
  };

  // let stripePromise = undefined;
  useEffect(() => {}, [selectedPlan]);

  const currencySymbol = currencyCode => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currencyCode,
    })
      .format(0)
      .replace(/0\.00/, '');
  };

  const PlanCard = ({ plan, toggleModal, isMonthly }) => (
    <Col md="4" key={plan.id || plan.title} className="mb-4">
      <Card className="p-0" style={{ borderColor: '#888888', height: '100%' }}>
        <CardBody>
          <h5 className="mt-0">
            {plan.title} {plan.popular && <Badge color="primary">Popular</Badge>}
          </h5>
          <span className="mt-3 py-3" style={{ color: '#888888' }}>
            {plan.description}
          </span>
          <hr />
          <h2 className="mt-3">
            {currencySymbol(plan.currency?.toUpperCase())} {isMonthly ? plan.price : plan.price / 12}/mo
          </h2>
          <span>{isMonthly ? 'Billed monthly' : 'Billed annually'}</span>

          {plan.isCurrentPlan && (
            <p className="text-muted" style={{ marginTop: '50px' }}>
              Your current plan
            </p>
          )}

          {!plan.isCurrentPlan && (
            <Row>
              <Col className="mt-3">
                <Button className="btn active btn-outline-theme-clr" onClick={() => toggleModal(plan)}>
                  Upgrade
                </Button>
              </Col>
            </Row>
          )}

          <hr />
          <ul className="list-unstyled text-start my-3">
            {plan?.features?.map((feature, i) => (
              <li key={i} className="mt-3">
                <span style={{ color: feature.includes('No') ? 'red' : 'green' }}>
                  {feature.includes('No') ? <CrosSvgIcon /> : <CheckSvgIcon />}
                </span>{' '}
                {feature}
              </li>
            ))}
          </ul>
        </CardBody>
      </Card>
    </Col>
  );

  const PlansSection = ({ rSelected, monthlyPlans, annualPlans, toggleModal }) => {
    const plans = rSelected === 2 ? monthlyPlans : annualPlans;
    const isMonthly = rSelected === 2;

    return (
      <Row className="justify-content-center plans-inner mt-5">
        {plans.map(plan => (
          <PlanCard key={plan.id || plan.title} plan={plan} toggleModal={toggleModal} isMonthly={isMonthly} />
        ))}
      </Row>
    );
  };

  return (
    <div className="dashboard-app-p overflow-hidden">
      <Sidebar />
      <div className={'is_open p-3 d-flex flex-column'}>
        <Container className="py-3 text-center ">
          <h2 className="mb-2">Upgrade your plan</h2>
          <p className="text-muted">Get early access to our updates and new features</p>
          <Row className="pt-3">
            <Col md={12}>
              <ButtonGroup>
                <Button className="btn btn btn-outline-theme-clr" outline onClick={() => setRSelected(1)} active={rSelected === 1}>
                  Annual billing <Badge> Save 10% </Badge>
                </Button>
                <Button className="btn btn btn-outline-theme-clr" outline onClick={() => setRSelected(2)} active={rSelected === 2}>
                  Monthly billing
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          <PlansSection rSelected={rSelected} monthlyPlans={monthlyPlans} annualPlans={annualPlans} toggleModal={toggleModal} />;
          {/* Modal for Subscription Form */}
          {selectedPlan && (
            <Modal isOpen={modalOpen} toggle={() => toggleModal()} size="lg">
              <ModalHeader toggle={() => toggleModal()}>Subscribe to {selectedPlan.title}</ModalHeader>
              <ModalBody>
                <Elements stripe={stripePromise}>
                  <SubscriptionForm
                    title={`Subscribe to ${selectedPlan.title}`}
                    price={`${currencySymbol(selectedPlan.currency?.toUpperCase())}${selectedPlan.price}`}
                    plan_id={selectedPlan?.plan_id}
                    toggleModal={toggleModal}
                    plan_div_id={selectedPlan?.plan_div_id}
                  />
                </Elements>
              </ModalBody>
            </Modal>
          )}
        </Container>
      </div>
    </div>
  );
};

export default PricingPlans;
