import axios from 'axios';
import { Storage } from 'react-jhipster';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { AppThunk } from '../../../../../app/config/store';
import { setLocale } from '../../../../../app/shared/reducers/locale';
import { serializeAxiosError } from './reducer.utils';
import { cleanEntity } from '../../../../../app/shared/util/entity-utils';

export const initialState = {
  loading: false,
  isAuthenticated: false,
  account: {} as any,
  errorMessage: null as unknown as string, // Errors returned from server side
  redirectMessage: null as unknown as string,
  sessionHasBeenFetched: false,
  logoutUrl: null as unknown as string,
};
interface uploadFile {
  file: string | null;
  fileUseType: string | null;
}

export type AuthenticationState = Readonly<typeof initialState>;

const apiUrl = '/api/user-profile-management/user-profile-update';
const uploadProfilePic = 'api/account-management/profile-upload';
const getProfilePicUrl = '/api/account-management/user-profile';

// Actions

export const getSession = (): AppThunk => async (dispatch, getState) => {
  await dispatch(getAccount());

  const { account } = getState().authentication;
  if (account && account.langKey) {
    const langKey = Storage.session.get('locale', account.langKey);
    await dispatch(setLocale(langKey));
  }
};

export const getAccount = createAsyncThunk('authentication/get_account', async () => axios.get<any>('api/account'), {
  serializeError: serializeAxiosError,
});

export const logoutServer = createAsyncThunk('authentication/logout', async () => axios.post<any>('api/logout', {}), {
  serializeError: serializeAxiosError,
});

export const updateAccount = createAsyncThunk(
  'authentication/update_account',
  async (entity: any, thunkAPI) => {
    const result = await axios.put<any>(`${apiUrl}`, cleanEntity(entity));
    // console.log("updateEntity", result);
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const logout: () => AppThunk = () => async dispatch => {
  await dispatch(logoutServer());
  // fetch new csrf token
  dispatch(getSession());
};

export const clearAuthentication = messageKey => dispatch => {
  dispatch(authError(messageKey));
  dispatch(clearAuth());
};

export const uploadProfileImage = createAsyncThunk(
  'authentication/create_entity',
  async (entity: uploadFile, thunkAPI) => {
    const result = await axios.post<uploadFile>(uploadProfilePic, cleanEntity(entity), {
      headers: { 'content-type': 'multipart/form-data' },
    });
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const getProfilePic = createAsyncThunk(
  'authentication/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${getProfilePicUrl}`;
    return axios.get<any>(requestUrl);
  },
  { serializeError: serializeAxiosError },
);

export const AuthenticationSlice = createSlice({
  name: 'authentication',
  initialState: initialState as AuthenticationState,
  reducers: {
    authError(state, action) {
      return {
        ...state,
        redirectMessage: action.payload,
      };
    },
    clearAuth(state) {
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getAccount.rejected, (state, action) => ({
        ...state,
        loading: false,
        isAuthenticated: false,
        sessionHasBeenFetched: true,
        errorMessage: action.error.message,
      }))
      .addCase(getAccount.fulfilled, (state, action) => {
        const isAuthenticated = action.payload && action.payload.data && action.payload.data.activated;
        return {
          ...state,
          isAuthenticated,
          loading: false,
          sessionHasBeenFetched: true,
          account: action.payload.data,
        };
      })
      .addCase(logoutServer.fulfilled, (state, action) => ({
        ...initialState,
        logoutUrl: action.payload.data.logoutUrl,
      }))
      .addCase(getAccount.pending, state => {
        state.loading = true;
      });
  },
});

export const { authError, clearAuth } = AuthenticationSlice.actions;

// Reducer
export default AuthenticationSlice.reducer;
