import '../../content/css/common.css';
import ChatBot from './ChatBot';
import React, { useEffect, useRef, useState } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { Row, Col, Button, Navbar, Nav, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Label } from 'reactstrap';
import { useAppDispatch, useAppSelector } from '../../../../../app/config/store';
import { overridePaginationStateWithQueryParams } from '../../../../../app/shared/util/entity-utils';
import { createEntity, getEntities, getRemainingMessagesLimit } from '../../entities/ai-chat/ai-chat.reducer';
import { ITEMS_PER_PAGE } from '../../../../../app/shared/util/pagination.constants';
import { CollapseIcon, NewChat } from '../../shared/icon-svg/icon';

import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { renderToString } from 'react-dom/server';
interface Props {
  cardCols?: string;
}
import { setShowTemplates, setUserOldConversationId } from '../../../../../app/entities/chat-dashboard/chat-dashboard.reducer';
import ChatBotDetailView from './ChatBotDetailsView';
import { getPaginationState } from 'react-jhipster';
import AichatListView from './AichatListView';
import { LoadingBar } from 'react-redux-loading-bar';
// import { AccountMenu } from '../../../../../app/shared/layout/menus';
import { AccountMenu } from '../../shared/layout/menus';
import { getSession } from '../../../../../app/shared/reducers/authentication';
import { getProfile } from '../../../../../app/shared/reducers/application-profile';
import { hideHeader, showHeader } from '../../shared/layout/header/header.reducer';
import { getEntities as getAiAssistants } from '../../entities/ai-assitant/ai-assitant.reducer';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { hasAnyAuthority } from '../../../../../app/shared/auth/private-route';
import { AUTHORITIES } from '../../../../../app/config/constants';

export const AiChat = (props: Props) => {
  const userPromptFullText = useAppSelector(state => state.chatDashboard.userPromptFullText);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isSidebarCollapsed, setSidebarCollapsed] = useState(false);
  const isAdmin = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.ADMIN]));

  useEffect(() => {
    dispatch(getSession());
    dispatch(getProfile());
    dispatch(getAiAssistants({ size: 100 }));
  }, []);

  const toggleSidebar = () => {
    setSidebarCollapsed(!isSidebarCollapsed);
  };

  const params = useParams<'id'>().id;
  const [userConversation, setUserConversation] = useState('');
  const [userConversationAI, setUserConversationAI] = useState<any>();
  const [userConversationId, setUserConversationId] = useState(null);
  const showTemplates = useAppSelector(state => state.chatDashboard.showTemplates);
  const [loading, setLoading] = useState(false);
  const [timeoutLoading, setTimeoutLoading] = useState(true);
  const [showLoading, setShowLoading] = useState(true);
  const userOldConversationId = useAppSelector(state => state.chatDashboard.userOldConversationId);
  const [generatingChatGptConversationId, setGeneratingChatGptConversationId] = useState('');
  const newConversation = useAppSelector(state => state.aIChat.entity);
  const [userNameInital, setUserNameInital] = useState('');
  var dataList = useAppSelector(state => state.aIChat.entities);
  const account = useAppSelector(state => state.authentication.account);
  const aiAssitantList = useAppSelector(state => state.aiAssitant.entities);
  const loadingAiAssistant = useAppSelector(state => state.aiAssitant.loading);
  const pageLocation = useLocation();
  const [error, setError] = useState('');
  const toggleMenu = () => setMenuOpen(!menuOpen);
  const [menuOpen, setMenuOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [userSelectedConversationId, setUserSelectedConversationId] = useState(null);
  const [isOpenFavourites, setIsOpenFavourites] = useState(false);
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  const messageCount = useAppSelector(state => state.aiChat?.messageCount);
  const [messageCountCurrent, setMessageCountCurrent] = useState(0);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [assistant, setAssistant] = useState('');
  const [generatedMessage, setGeneratedMessage] = useState<any>();
  const [showQueries, setShowQueries] = useState(false);
  const { id } = useParams<'id'>();
  const previousAssistantId = useRef();

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getPaginationState(pageLocation, ITEMS_PER_PAGE, 'id'), pageLocation.search),
  );

  const configUserChars = account => {
    if (account.firstName != null && account.firstName != undefined && account.firstName != '') {
      setUserNameInital(account.firstName.charAt(0));
    } else {
      setUserNameInital(account?.email.charAt(0));
    }
  };

  const [chatTitleLoaded, setChatTitleLoaded] = useState({
    chatId: null,
    isTitleLoaded: false,
  });

  useEffect(() => {}, [params]);

  useEffect(() => {
    // Run code only for new conversations
    let newChatId = newConversation.id;
    if (newConversation.title == null) {
      setUserConversationId(newChatId);
      handleUpdateChatTitle(newChatId, false);
      setShowTemplates(true);
      if (id) {
        dispatch(
          getEntities({
            page: paginationState.activePage - 1,
            size: paginationState.itemsPerPage,
            sort: `${paginationState.sort},${paginationState.order}&assistantId=${id}`,
            // query: `assistantId.equals=${id}`,
          }),
        );
      }
    }
  }, [newConversation]);

  useEffect(() => {
    const { planType, trial, subscriptionActive } = account || {};
    const isBalanceZero = parseInt(messageCount) === 0;
    let promotMessage = 'Message CPAPilot';
    let showUpgradeEarly = false;
    let showUpgradeNow = false;
    let subscribeNow = false;

    if (planType === 'trial') {
      if (trial && isBalanceZero) {
        showUpgradeNow = true;
        promotMessage = 'Limit Exhausted. You may renew/upgrade your subscription.';
      } else {
        subscribeNow = true;
        promotMessage = 'No Subscription found. Please Subscribe to access service.';
      }
    } else if (planType === 'paid') {
      if (trial && isBalanceZero) {
        showUpgradeEarly = true;
        promotMessage = 'Trial limit Exhaused. You may Pay Now to end trial and continue accessing the service.';
      } else if (subscriptionActive && isBalanceZero) {
        showUpgradeNow = true;
        promotMessage = 'Limit Exhausted. You may renew/upgrade your subscription.';
      } else if (!subscriptionActive) {
        subscribeNow = true;
        promotMessage = 'Please renew your Subscription.';
      }
    }

    // setShowUpgradeEarly(showUpgradeEarly);
    // setShowUpgradeNow(showUpgradeNow);
    // setSubscribeNow(subscribeNow);
    // setisPromotMessage(promotMessage);
  }, [account, messageCount]);

  const updateMessageCount = async () => {
    let resp = await dispatch(getRemainingMessagesLimit());
    let balance = resp && (resp.payload as { data: number })?.data;

    setMessageCountCurrent(balance);
  };

  const useFetchMessagesLimit = () => {
    let interval;
    const fetchMessagesLimit = async () => {
      let resp = await dispatch(getRemainingMessagesLimit());
      let balance = resp && (resp.payload as { data: number })?.data;

      setMessageCountCurrent(balance);
      if (balance == 0 || balance == null) {
        if (!interval) {
          interval = setInterval(fetchMessagesLimit, 15000); // Fetch every 15 seconds
        }
      } else if (interval) {
        clearInterval(interval); // Clear the interval if balance is not zero
        interval = null;
      }
    };
    fetchMessagesLimit(); // Initial fetch
  };

  const onStartNewConversation = async () => {
    setUserConversationId('');
    await dispatch(
      createEntity({
        user: account,
        aiAssitant: assistant ? { id: assistant?.id } : '',
      }),
    );
  };
  const handleConversation = async generatedMessage => {
    setUserConversationAI(generatedMessage);
    await updateMessageCount();
  };

  useEffect(() => {}, [userConversationAI]);

  useEffect(() => {
    // if (dataList && dataList.length > 0) {
    //   console.log("Iff", dataList);

    //   // let theChat = dataList.find(x => x.user?.id == account?.id);
    // } else {
    //   console.log("Elseeee");
    //   setTimeout(() => {
    //     setTimeoutLoading(false);
    //   }, 1000);
    // }
    setTimeout(() => {
      setTimeoutLoading(false);
    }, 1000);
  }, [dataList]);

  useEffect(() => {
    localStorage.setItem('context', 'all');
    configUserChars(account);
    useFetchMessagesLimit();
  }, []);

  const handleUpdateChatTitle = (chatId, isTitleLoaded) => {
    setChatTitleLoaded({
      ...chatTitleLoaded,
      isTitleLoaded: isTitleLoaded,
      chatId: userConversationId,
    });
  };

  useEffect(() => {
    if (account && account.id) {
      dispatch(
        getEntities({
          page: paginationState.activePage - 1,
          size: paginationState.itemsPerPage,
          sort: `${paginationState.sort},${paginationState.order}&assistantId=${id}`,
          // query: `assistantId.equals=${id}&assistantId=${id}`,
        }),
      );
    }
  }, [account, id]);

  const reloadAllEntitiesOnStreamingEnd = () => {
    if (userConversationId == chatTitleLoaded.chatId && !chatTitleLoaded.isTitleLoaded) {
      dispatch(
        getEntities({
          page: paginationState.activePage - 1,
          size: paginationState.itemsPerPage,
          sort: `${paginationState.sort},${paginationState.order}&assistantId=${id}`,
          // query: `assistantId.equals=${id}`,
        }),
      );
    }
    setChatTitleLoaded({
      ...chatTitleLoaded,
      isTitleLoaded: true,
    });
  };

  const handleUserSelectedConversation = async (id, oldConversation) => {
    dispatch(setUserOldConversationId(id));

    if (id == generatingChatGptConversationId) {
      dispatch(setShowTemplates(false));
      setUserConversation(oldConversation + userPromptFullText + (userConversationAI ? userConversationAI : ''));
      setShowLoading(true);
    } else {
      if (oldConversation == '') {
        dispatch(setShowTemplates(true));
      }
      setUserConversation(oldConversation);
      setShowLoading(false);
    }
  };

  const onClickStartNewChatPlusButton = async () => {
    setUserConversation('');
    setUserSelectedConversationId(null);
    setShowTemplates(true);
    const date = new Date().toISOString();
    // console.log("date", date);

    await dispatch(
      createEntity({
        user: account,
        aiAssitant: { id: assistant?.id },
        createdDate: date,
      }),
    );
  };

  const updateConversation = async generatedMessage => {
    let aiResp = renderToString(
      <Markdown
        remarkPlugins={[remarkGfm]}
        components={{
          a: ({ node, ...props }) => {
            return (
              <a {...props} target="_blank">
                {props.children}
              </a>
            );
          },
        }}
      >
        {generatedMessage}
      </Markdown>,
    );
    setUserConversation(userConversation => userConversation + aiResp);
  };

  useEffect(() => {
    dispatch(hideHeader()); // Hide header when this component is mounted
    return () => {
      dispatch(showHeader()); // Show header again when leaving this route
    };
  }, [dispatch]);

  const toggle = () => setDropdownOpen(prevState => !prevState);

  useEffect(() => {
    if (id == '' || (id !== assistant?.id && aiAssitantList.length > 0)) {
      const aiAssistant = aiAssitantList.find(item => item.id == id);
      setAssistant(aiAssistant);
    }
  }, [aiAssitantList]);

  useEffect(() => {
    const newPath = `${window.location.origin}/copilot/${assistant?.id}`;
    if (assistant?.id && window.location.href !== newPath) {
      previousAssistantId.current = assistant.id;
      window.history.pushState({}, '', newPath);
      navigate(`/copilot/${assistant?.id}`);
      onClickStartNewChatPlusButton();
    }
  }, [assistant]);

  useEffect(() => {
    if (userConversation == null || userConversation == undefined || userConversation == '' || userConversation?.length == 0)
      setShowQueries(true);
    else if (generatedMessage?.length == 0) setShowQueries(true);
    else if (!userConversationId && !timeoutLoading) setShowQueries(true);
    else setShowQueries(false);
  }, [generatedMessage, userConversation, userConversationId, timeoutLoading]);

  // console.log("userConverstion", userConversation);
  // console.log("generatedMessage", generatedMessage);
  console.log('showQueries', showQueries);

  return (
    <div>
      <div className="contentRow">
        <div className="my-component mt-4 mb-4">
          <div className="ai-chat-wrapper">
            <div className="page-style p-3">
              <div className={`mobilesidebar_open side-bar p-0 flex ${isSidebarCollapsed ? 'collapsed' : ''}`}>
                <button onClick={toggleSidebar} className="sidebar-toggle-btn">
                  {isSidebarCollapsed ? <CollapseIcon /> : <CollapseIcon />}
                </button>
                <div className={'open-desktop-sidebar w-100 chat_history'}>
                  <div className="profile-display">
                    <div className="mobile_display mb-2">
                      <img
                        className="logo"
                        src="content/images/knolli-logo-img.svg"
                        alt="Logo"
                        onClick={() => navigate('/admin/dashboard')}
                      />
                      <img
                        className="logo-icon"
                        src="content/images/knolli-icon.svg"
                        alt="Logo"
                        onClick={() => navigate('/admin/dashboard')}
                      />
                      <div className="d-flex justify-content-start mt-3">
                        <Button
                          color="primary"
                          className=""
                          onClick={onClickStartNewChatPlusButton}
                          disabled={messageCountCurrent == 0 || !assistant}
                        >
                          <NewChat /> Start new chat
                        </Button>
                      </div>
                    </div>
                    {error && <div className="text-danger">{error}</div>}
                  </div>

                  <div className="d-flex justify-content-center w-100 px-2">
                    <Row className="justify-content-center w-100">
                      <Col md="12">
                        <Label>Select a Knolli</Label>
                      </Col>
                      <Col md="12">
                        <Dropdown isOpen={dropdownOpen} toggle={toggle} className="w-100">
                          <DropdownToggle className="assistantDropdown w-100 d-flex align-items-center text-start" caret>
                            <span className="text-truncate w-100 d-inline-block">
                              {assistant ? assistant?.qdrantCollectionName : 'Select'}
                            </span>
                          </DropdownToggle>
                          <DropdownMenu>
                            {aiAssitantList?.length > 0 &&
                              aiAssitantList.map(item => (
                                <DropdownItem key={item?.id} active={item?.id == id} onClick={() => setAssistant(item)}>
                                  {item.qdrantCollectionName}
                                </DropdownItem>
                              ))}
                          </DropdownMenu>
                        </Dropdown>
                      </Col>
                    </Row>
                  </div>

                  <div className="ai-chat-list-view border-top">
                    <AichatListView
                      updateSelectedConversation={handleUserSelectedConversation}
                      updateUserConversation={setUserConversation}
                      userOldConversationId={userOldConversationId}
                      isOpenFavourites={isOpenFavourites}
                      setUserConversationId={setUserConversationId}
                      userConversationId={userConversationId}
                      setGeneratedMessage={setGeneratedMessage}
                    />
                  </div>

                  <div id="sidebar_user_profile">
                    <LoadingBar className="loading-bar" />
                    <Navbar data-cy="navbar" dark expand="md" className="jh-navbar">
                      {/* <NavbarToggler aria-label="Menu" onClick={toggleMenu} /> */}
                      {/* <Collapse isOpen={menuOpen} navbar> */}
                      <Nav id="header-tabs" className="ms-auto" navbar>
                        {/* {props.isAuthenticated && props.isAdmin && <AdminMenu showOpenAPI={props.isOpenAPIEnabled} />} */}
                        <AccountMenu isAuthenticated={isAuthenticated} />
                      </Nav>
                      {/* </Collapse> */}
                    </Navbar>
                  </div>
                </div>
              </div>

              <div className={'is_open bg-white p-3 d-flex flex-column justify-content-between'}>
                {isAdmin && (
                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button color="" id="save-entity" type="button" onClick={() => navigate('/admin/manage')}>
                      <FontAwesomeIcon icon={faArrowLeft} color="#1d3461" />
                      <span style={{ color: '#1d3461', paddingLeft: '4px' }}>Back</span>
                    </Button>
                  </div>
                )}
                {showQueries && (
                  <Row className="chatbot justify-content-center">
                    <Col sm="12" md="6">
                      <div className="text-center welcome-text mt-1">
                        <h4>Welcome To {dataList?.data?.qdrantCollectionName || `your Knolli`}</h4>
                        <p>{dataList?.data?.userSystemMessage || `Ask away your mind`}</p>
                        <p>You have {messageCountCurrent} messages remaining in your limit.</p>
                      </div>
                    </Col>
                  </Row>
                )}

                <Row className="mt-xs-3 chat-search-wrap justify-content-center">
                  <Col sm="12" md="12" lg="10" xl="8" className="d-flex flex-column justify-content-between">
                    <ChatBotDetailView
                      showTemplates={showTemplates}
                      userConversation={userConversation}
                      userConversationAI={userConversationAI}
                      loading={showLoading ? loading : false}
                    />
                    <ChatBot
                      handleConversation={handleConversation}
                      newConvMethod={onStartNewConversation}
                      setUserConversation={setUserConversation}
                      updateConversation={updateConversation}
                      userConversationId={userConversationId}
                      userConversation={userConversation}
                      userOldConversationId={userOldConversationId}
                      setGeneratingChatGptConversationId={setGeneratingChatGptConversationId}
                      // selectedPersona={selectedPersona}
                      messageCount={messageCountCurrent}
                      showTemplates={showTemplates}
                      setLoading={setLoading}
                      setShowLoading={setShowLoading}
                      loading={loading}
                      reloadEntitiesOnStreamingEnd={reloadAllEntitiesOnStreamingEnd}
                      assistantId={assistant?.id}
                      messageCountCurrent={messageCountCurrent}
                    />
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AiChat;
