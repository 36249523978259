import { IAiAssitant } from 'app/shared/model/ai-assitant.model';

export interface IUserIntent {
  id?: number;
  name?: string;
  description?: string;
  samplePrompts?: string | null;
  disablePrompt?: boolean | null;
  promptRefinement?: string | null;
  aiAssitant?: IAiAssitant | null;
}

export const defaultValue: Readonly<IUserIntent> = {
  disablePrompt: false,
};
