import React, { useEffect, useState } from 'react';
import { BarChart } from '@mui/x-charts/BarChart';

const chartSetting = {
  yAxis: [
    {
      label: 'Queries',
    },
  ],
  width: 500,
  height: 400,
};

export const QueriesPerDayGraph = ({ data, activeTab }) => {
  // Validate data format
  const isValidData = data?.queriesPerDay?.every(item => item.date && typeof item.numberOfQueries === 'number');

  if (!isValidData) {
    console.error('Invalid or No data available for chart');
    return <p>No data available or data format is incorrect</p>;
  }

  const [newData, setNewData] = useState([]);
  useEffect(() => {
    let newArr = data.queriesPerDay.map(ele => {
      let tempobj = {};
      tempobj['numberOfQueries'] = ele.numberOfQueries;
      let tempdate = new Date(ele.date);
      const formattedDate = `${tempdate.getFullYear()}-${String(tempdate.getMonth()).padStart(2, '0')}-${String(tempdate.getDate()).padStart(2, '0')}`;
      const formattedTime = `${String(tempdate.getUTCHours()).padStart(2, '0')}:${String(tempdate.getMinutes()).padStart(2, '0')}`;
      tempobj['byHour'] = formattedTime;
      tempobj['byDay'] = formattedDate;
      return tempobj;
    });
    setNewData(newArr);
  }, [data]);

  return (
    <BarChart
      dataset={newData}
      xAxis={[
        {
          scaleType: 'band',
          dataKey: activeTab == 'today' || activeTab == 'yesterday' ? 'byHour' : 'byDay',
          label: activeTab == 'today' || activeTab == 'yesterday' ? 'Hour' : 'Date',
        },
      ]}
      series={[
        {
          dataKey: 'numberOfQueries',
          label: 'Number of Queries',
          color: '#00523f',
        },
      ]}
      layout="vertical"
      {...chartSetting}
    />
  );
};
