import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';

import { Button, Row, Col, Spinner, Badge } from 'reactstrap';
import { ValidatedField, ValidatedForm, getPaginationState } from 'react-jhipster';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from '../../../../../app/shared/util/pagination.constants';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from '../../../../../app/config/store';
import { updateEntity as createUserProfileFields } from '../../entities/knolli-ai-user-profile-field/knolli-ai-user-profile-field.reducer';
import { getUsers } from '../../../../../app/shared/reducers/user-management';
// import { AiAssistantType } from '../../../../../app/shared/model/enumerations/ai-assistant-type.model';
import { getEntity, updateEntity, createEntity, reset } from '../../../../../app/entities/ai-assitant/ai-assitant.reducer';
import { getEntities } from '../../../../../app/entities/file-upload/file-upload.reducer';
import Sidebar from '../sidebar/sidebar';
// import { uploadFile } from '../../entities/ai-chat/ai-chat.reducer';
import { overridePaginationStateWithQueryParams } from '../../../../../app/shared/util/entity-utils';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { analyticsTrack } from '../../shared/services/analytics';

export const AddAiAssistant = () => {
  const { id } = useParams<'id'>();
  const isNew = id === undefined;
  const [currentStep, setCurrentStep] = useState(1);
  const aiAssitantEntity = useAppSelector(state => state.aiAssitant.entity);
  const updating = useAppSelector(state => state.aiAssitant.updating);
  const updateSuccess = useAppSelector(state => state.aiAssitant.updateSuccess);
  // const aiAssistantTypeValues = Object.keys(AiAssistantType);
  const account = useAppSelector(state => state.authentication.account);
  const updatedProfileFields = useAppSelector(state => state.knolliAIUserProfileField.updateSuccess);
  const updatingProfileFields = useAppSelector(state => state.knolliAIUserProfileField.updating);

  const [systemMessage, setSystemMessage] = useState('');
  const [qdrantCollectionName, setQdrantCollectionName] = useState('');
  const [userProfileFields, setUserProfileFields] = useState([]);
  const [profileFields, setProffileFields] = useState('');
  const [tempAiAssistantId, setTempAiAssistantId] = useState('');

  const handleClose = () => {
    navigate('/admin/manage');
  };

  useEffect(() => {
    if (isNew) {
      analyticsTrack('Add AI Assisnant Form');
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getUsers({}));
  }, []);

  useEffect(() => {
    if (aiAssitantEntity) {
      setSystemMessage(aiAssitantEntity.userSystemMessage);
      setQdrantCollectionName(aiAssitantEntity.qdrantCollectionName);
      analyticsTrack('Update AI Assisnant Form', { assitant: { name: aiAssitantEntity.qdrantCollectionName, id: id } });
    }
  }, [aiAssitantEntity]);

  useEffect(() => {
    if (isNew) {
      if (updatedProfileFields) {
        handleClose();
      }
    } else {
      if (updateSuccess) {
        handleClose();
      }
    }
  }, [updatedProfileFields, updateSuccess]);

  // eslint-disable-next-line complexity
  const saveEntity = async values => {
    if (values.id !== undefined && typeof values.id !== 'number') {
      values.id = Number(values.id);
    }

    if (!qdrantCollectionName.trim() || !systemMessage.trim()) {
      setErrorMessage('* Please enter a valid value. Spaces are not allowed.');
    } else {
      setErrorMessage('');

      const entity = {
        ...aiAssitantEntity,
        ...values,
        userSystemMessage: systemMessage.trim(),
        qdrantCollectionName: qdrantCollectionName.trim(),
        aiAssistantType: 'COPILOT',
        user: account,
      };

      if (isNew) {
        analyticsTrack('Created AI Assisnant', { assitant: { name: entity.qdrantCollectionName } });
        let { payload } = await dispatch(createEntity(entity));
        if (payload?.status == 201) {
          navigate(`/admin/manage/${payload?.data?.id}/fields`);
          // setCurrentStep(2);
          // setTempAiAssistantId(payload?.data?.id);
        }
      } else {
        analyticsTrack('Updated AI Assisnant', { assitant: { name: entity.qdrantCollectionNam, id: entity.id } });
        dispatch(updateEntity(entity));
      }
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          aiAssistantType: 'COPILOT',
          ...aiAssitantEntity,
          userSystemMessage: systemMessage,
          qdrantCollectionName: qdrantCollectionName,
          user: aiAssitantEntity?.user?.id,
        };

  const dispatch = useAppDispatch();

  const pageLocation = useLocation();
  const navigate = useNavigate();

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getPaginationState(pageLocation, ITEMS_PER_PAGE, 'id'), pageLocation.search),
  );

  const sourceDocumentList = useAppSelector(state => state.fileUpload.entities);
  const loading = useAppSelector(state => state.sourceDocument.loading);
  const totalItems = useAppSelector(state => state.sourceDocument.totalItems);
  const [modal, setModal] = useState(false);
  const [file, setFile] = useState();
  const [errorMessage, setErrorMessage] = useState('');

  const toggle = () => setModal(!modal);

  const getAllEntities = () => {
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
        query: `aiAssitantId.equals=${id}`,
      }),
    );
  };

  const sortEntities = () => {
    getAllEntities();
    // const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    // if (pageLocation.search !== endURL) {
    //   navigate(`${pageLocation.pathname}${endURL}`);
    // }
  };

  useEffect(() => {
    if (!isNew) {
      sortEntities();
    }
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  useEffect(() => {
    const params = new URLSearchParams(pageLocation.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [pageLocation.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  // const handlePagination = currentPage =>
  //   setPaginationState({
  //     ...paginationState,
  //     activePage: currentPage,
  //   });

  // const setSelectedFile = event => {
  //   // console.log("event",event);
  //   setFile(event.target.files[0]);
  // };

  // const getSortIconByFieldName = (fieldName: string) => {
  //   const sortFieldName = paginationState.sort;
  //   const order = paginationState.order;
  //   if (sortFieldName !== fieldName) {
  //     return faSort;
  //   } else {
  //     return order === ASC ? faSortUp : faSortDown;
  //   }
  // };

  const handleProfileFields = () => {
    if (profileFields.trim() !== '') {
      setUserProfileFields(prevFields => [...prevFields, { name: profileFields.trim() }]);
      setProffileFields(''); // Reset the input field
    }
  };
  const handleSaveProfileFields = async () => {
    if (userProfileFields.length > 0 && tempAiAssistantId != '') {
      const entityFields = { fields: userProfileFields, aiAssistantId: tempAiAssistantId };
      await dispatch(createUserProfileFields(entityFields));
    } else {
      handleClose();
    }
  };
  const removeUserProfileFields = index => {
    if (index >= 0 && index < userProfileFields.length) {
      const updatedFields = userProfileFields.filter((_, i) => i !== index);
      setUserProfileFields(updatedFields);
    }
  };
  return (
    <div className="dashboard-app-p overflow-hidden">
      <Sidebar />
      <div className={'is_open p-3 d-flex flex-column'}>
        <h2
          id="ai-assitant-heading"
          data-cy="AiAssitantHeading"
          className="main-title d-flex justify-content-between gap-3 align-items-center"
        >
          {isNew ? `Add a Knolli` : `Editing Knolli - ${qdrantCollectionName}`}
          {/* <div className="d-flex justify-content-end">
              <Button className="theme-btn btn" onClick={handleWizard} style={{ marginRight: '8px' }}>
                <AiGeneratedImage />
                &nbsp; Wizard
              </Button>
              <Button className="theme-btn btn" onClick={addAssistant}>
                <FontAwesomeIcon icon="plus" />
                &nbsp; Add a Knolli
              </Button>
            </div> */}
        </h2>
        <p>{isNew ? `Let us know what your Knolli will do.` : `Update your Knolli for better results.`}</p>

        {loading && (
          <Col className="col-md-12">
            <div className="app-loading" style={{ height: '500px', width: '300px', marginInline: 'auto' }}>
              <div className="conductor_loader-index"></div>
            </div>
          </Col>
        )}

        <Row className="card-wrapper pt-4 p-3">
          <Col md={'12'} className="my-component p-3">
            {/* <Progress multi>
              <Progress
              bar
                color={currentStep == 1 ? "success"}
                value={50}
              >Step {currentStep}</Progress>
            {currentStep == 2 && <Progress bar
                color="warning"
                value={50}
              >Step {currentStep}</Progress>
            }
            </Progress> */}

            <Row className="pt-3">
              {currentStep == 1 && (
                <Col md="12">
                  <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity} className="row g-5">
                    <Col md="10" lg="8">
                      <div className="form">
                        {/* <UncontrolledTooltip target="aiAssistantTypeLabel">
                              <Translate contentKey="knolliaiApp.aiAssitant.help.aiAssistantType" />
                            </UncontrolledTooltip> */}
                        <ValidatedField
                          label={
                            isNew
                              ? `What will be your Knolli's name?(eg: LegalBuddy)`
                              : `Like to change Knolli's name?(We suggest that you don't)`
                          }
                          id="ai-assitant-qdrantCollectionName"
                          name="qdrantCollectionName"
                          data-cy="qdrantCollectionName"
                          type="text"
                          required
                          defaultValue={qdrantCollectionName}
                          onChange={e => {
                            setQdrantCollectionName(e.target.value);
                          }}
                        />
                        <ValidatedField
                          label={isNew ? 'What will your knolli do?' : 'Refine what your knolli to do better?'}
                          id="ai-assitant-userSystemMessage"
                          name="userSystemMessage"
                          data-cy="userSystemMessage"
                          type="textarea"
                          placeholder="Example: Helps legal professionals with document review and legal research"
                          required
                          defaultValue={systemMessage}
                          onChange={e => {
                            setSystemMessage(e.target.value);
                          }}
                        />
                      </div>
                      {/* <Label>Example: "Helps legal professionals with document review and legal research"</Label> */}
                    </Col>
                    {/* <Col md="12" lg="12" style={{ marginTop: '20px' }}>
                          <ValidatedField
                            label={translate('knolliaiApp.aiAssitant.aiAssistantType')}
                            id="ai-assitant-aiAssistantType"
                            name="aiAssistantType"
                            data-cy="aiAssistantType"
                            type="select"
                          >
                            {aiAssistantTypeValues.map(aiAssistantType => (
                              <option value={aiAssistantType} key={aiAssistantType}>
                                {translate('knolliaiApp.AiAssistantType.' + aiAssistantType)}
                              </option>
                            ))}
                          </ValidatedField>
                        </Col> */}
                    {errorMessage && <div style={{ color: 'red', marginTop: '5px', fontSize: '14px' }}>{errorMessage}</div>}
                    <Col md={12} className="d-flex justify-content-between mt-4">
                      {/* <Button color="" id="save-entity" type="button" disabled={updating} onClick={() => navigate('/admin/manage')}>
                            <FontAwesomeIcon icon={faArrowLeft} color="#1d3461" />
                            <span style={{ color: '#1d3461', paddingLeft: '4px' }}>Back</span>
                          </Button> */}
                      {loading ? (
                        <Spinner color="primary" />
                      ) : (
                        <Button
                          color="primary"
                          className="theme-btn"
                          id="save-entity"
                          data-cy="entityCreateSaveButton"
                          type="submit"
                          disabled={updating}
                        >
                          {isNew ? 'Next' : 'Update'}
                        </Button>
                      )}
                    </Col>
                  </ValidatedForm>
                </Col>
              )}

              {currentStep == 2 && (
                <>
                  <Col md="12" lg="12" style={{ marginTop: '20px' }}>
                    {userProfileFields &&
                      userProfileFields?.length > 0 &&
                      userProfileFields?.map((fields, index) => (
                        <>
                          <Badge pill color="primary" className="m-2 fs-6" key={index}>
                            {fields?.name}
                            <Button close className="bg-white" onClick={() => removeUserProfileFields(index)} />
                          </Badge>
                        </>
                      ))}
                  </Col>
                  <Col md="12" lg="12" style={{ marginTop: '20px' }}>
                    <Row className="align-items-center">
                      <Col md="6" lg="6">
                        <ValidatedField
                          label="Profile Fields"
                          id="ai-assitant-aiAssistantFields"
                          name="aiAssistantFields"
                          data-cy="aiAssistantFields"
                          type="text"
                          onChange={e => setProffileFields(e.target.value)}
                          value={profileFields}
                        ></ValidatedField>
                      </Col>
                      <Col md="6" lg="6" className="mt-3">
                        <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" onClick={handleProfileFields}>
                          Add
                        </Button>
                      </Col>
                    </Row>
                  </Col>

                  <Col md={12} className="d-flex justify-content mt-4" style={{ justifyContent: 'space-between' }}>
                    <Button color="" id="save-entity" type="button" disabled={updatingProfileFields} onClick={() => setCurrentStep(1)}>
                      <FontAwesomeIcon icon={faArrowLeft} color="#1d3461" />
                      <span style={{ color: '#1d3461', paddingLeft: '4px' }}>Back</span>
                    </Button>
                    {loading ? (
                      <Spinner color="primary" />
                    ) : (
                      <Button
                        color="primary"
                        id="save-entity"
                        data-cy="entityCreateSaveButton"
                        type="submit"
                        disabled={updatingProfileFields}
                        onClick={handleSaveProfileFields}
                      >
                        Save
                      </Button>
                    )}
                  </Col>
                </>
              )}
            </Row>
          </Col>
          {/* {!isNew && (
            <Col md="7">
              <ManageDocument />
            </Col>
          )} */}
        </Row>
      </div>
    </div>
  );
};

export default AddAiAssistant;
