import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { Button, Row, Col, Table, Label, Spinner } from 'reactstrap';
import { ValidatedField } from 'react-jhipster';
import { useAppDispatch, useAppSelector } from '../../../../../app/config/store';
import {
  updateEntity as createUserProfileFields,
  deleteEntity,
  getEntities,
} from '../../entities/knolli-ai-user-profile-field/knolli-ai-user-profile-field.reducer';
import Sidebar from '../sidebar/sidebar';
import { UserProfileFieldType } from '../../../../../app/shared/model/enumerations/user-profile-field-type.model';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFloppyDisk, faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
export const UserProfileFields = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const profileFieldsList = useAppSelector(state => state.knolliAIUserProfileField.entities?.data?.fields);
  const updatedProfileFields = useAppSelector(state => state.knolliAIUserProfileField.updateSuccess);
  const loading = useAppSelector(state => state.knolliAIUserProfileField.loading);
  const userFieldTypes = Object.values(UserProfileFieldType);
  const [errorMessage, setErrorMessage] = useState({ name: '', type: '' });

  const [userProfileFields, setUserProfileFields] = useState([]);
  const [newField, setNewField] = useState({ name: '', type: '', description: '' });
  const [editField, setEditField] = useState({ name: '', type: '', description: '' });
  const [editIndex, setEditIndex] = useState(null);

  // Fetch data on mount or when updatedProfileFields changes
  useEffect(() => {
    dispatch(getEntities({ query: `aiAssistantId=${id}` }));
  }, [id, updatedProfileFields, dispatch]);

  useEffect(() => {
    setUserProfileFields(profileFieldsList);
  }, [profileFieldsList]);

  useEffect(() => {
    setEditField({ ...userProfileFields[editIndex] });
  }, [editIndex]);

  const handleFieldChange = (e, isEdit = false) => {
    const { name, value } = e.target;
    const setter = isEdit ? setEditField : setNewField;
    setter(prev => ({ ...prev, [name]: value }));
    setErrorMessage({ name: '', type: '' });
  };

  const handleProfileFields = async e => {
    let entityFields = { aiAssistantId: id, fields: [] };
    const field = editIndex !== null ? editField : newField; // Use the right field object based on editIndex

    // Check if the field name and type are valid
    if (!field.name || !field.type) {
      const error = {};
      if (!field.name) error.name = 'Please enter a field name.';
      if (!field.type) error.type = 'Please select a field type.';
      setErrorMessage(error);
      return;
    }

    // Set the fields for entityFields
    entityFields.fields = [{ ...field }];

    const { payload } = await dispatch(createUserProfileFields(entityFields));
    if (payload?.status === 200) {
      setEditIndex(null);
      setNewField({ name: '', type: '', description: '' });
      setEditField({ name: '', type: '', description: '' });
      setErrorMessage({ name: '', type: '' });
    }
  };

  const removeUserProfileFields = async fieldId => {
    if (fieldId !== undefined) {
      await dispatch(deleteEntity({ fieldId, assistantId: id }));
      setUserProfileFields(prevFields => prevFields.filter(field => field?.fieldId !== fieldId));
    }
  };

  return (
    <div className="dashboard-app-p overflow-hidden">
      <Sidebar />
      <div className="is_open p-3 d-flex flex-column">
        <h2 id="ai-assitant-heading" className="main-title d-flex justify-content-between gap-3 align-items-center">
          Profile Fields
        </h2>
        <p>Add/Remove profile fields to/from Knolli for personalized results.</p>
        <Row className="card-wrapper pt-4 p-3">
          <Col md="12">
            <div className="my-component pt3">
              <Table>
                <thead>
                  <tr className="w-100">
                    <th className="w-25">Name</th>
                    <th className="w-25">Type</th>
                    <th className="w-25">Description</th>
                    <th className="w-25">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <ValidatedField
                        type="text"
                        name="name"
                        placeholder="Enter name"
                        value={newField.name}
                        onChange={e => handleFieldChange(e)}
                      />
                    </td>
                    <td>
                      <ValidatedField
                        type="select"
                        name="type"
                        placeholder="Enter type"
                        value={newField.type}
                        onChange={e => handleFieldChange(e)}
                      >
                        <option value="">Select type</option>
                        {userFieldTypes &&
                          userFieldTypes?.length > 0 &&
                          userFieldTypes?.map((type, index) => (
                            <option value={type} key={index}>
                              {type}
                            </option>
                          ))}
                      </ValidatedField>
                    </td>

                    <td>
                      <ValidatedField
                        type="text"
                        name="description"
                        placeholder="Enter description"
                        value={newField.description}
                        onChange={e => handleFieldChange(e)}
                      />
                    </td>
                    <td>
                      <Button className="theme-btn icon-btn" size="sm" onClick={handleProfileFields}>
                        <FontAwesomeIcon icon={faFloppyDisk} id="profile-fields" style={{ cursor: 'pointer' }} />
                      </Button>
                      {errorMessage && editIndex == null && (
                        <div className="text-danger">{errorMessage.name ? errorMessage.name : errorMessage.type}</div>
                      )}
                    </td>
                  </tr>
                  {userProfileFields && userProfileFields.length > 0 ? (
                    userProfileFields?.map((field, index) => (
                      <tr key={field.fieldId || index}>
                        {editIndex === index ? (
                          <>
                            <td>
                              <ValidatedField
                                type="text"
                                name="name"
                                value={editField.name}
                                onChange={e => handleFieldChange(e, true)}
                                required
                              />
                            </td>
                            <td>
                              <ValidatedField
                                type="select"
                                name="type"
                                placeholder="Enter type"
                                value={editField.type}
                                onChange={e => handleFieldChange(e, true)}
                                required
                              >
                                {' '}
                                <option value="">Select type</option>
                                {userFieldTypes &&
                                  userFieldTypes?.length > 0 &&
                                  userFieldTypes?.map((type, index) => (
                                    <option value={type} key={index}>
                                      {type}
                                    </option>
                                  ))}
                              </ValidatedField>
                            </td>
                            <td>
                              <ValidatedField
                                type="text"
                                name="description"
                                value={editField.description}
                                onChange={e => handleFieldChange(e, true)}
                              />
                            </td>
                            <td>
                              <Button onClick={handleProfileFields} className="theme-btn me-2">
                                Update
                              </Button>
                              {errorMessage && editIndex !== null && (
                                <div className="text-danger">{errorMessage.name ? errorMessage.name : errorMessage.type}</div>
                              )}
                            </td>
                          </>
                        ) : (
                          <>
                            <td>{field.name}</td>
                            <td>{field.type}</td>
                            <td>{field.description}</td>
                            <td>
                              <Button onClick={() => setEditIndex(index)} className="theme-btn icon-btn me-2 ">
                                <FontAwesomeIcon icon={faPen} id="profile-fields" style={{ cursor: 'pointer' }} />
                              </Button>
                              <Button onClick={() => removeUserProfileFields(field.fieldId)} className="theme-btn icon-btn me-2">
                                <FontAwesomeIcon icon={faTrash} id="profile-fields" style={{ cursor: 'pointer' }} />
                              </Button>
                            </td>
                          </>
                        )}
                      </tr>
                    ))
                  ) : loading ? (
                    <tr>
                      <td colSpan={4}>
                        <div className="d-flex justify-content-center">
                          <Spinner className="" />
                        </div>
                      </td>
                    </tr>
                  ) : (
                    <tr>
                      <td colSpan={4}>
                        <div className="d-flex justify-content-center">
                          <Label>No Profile Field found</Label>
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default UserProfileFields;
