import { useAppDispatch, useAppSelector } from '../../../../../app/config/store';
import { overridePaginationStateWithQueryParams } from '../../../../../app/shared/util/entity-utils';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from '../../../../../app/shared/util/pagination.constants';
import React, { useEffect, useState } from 'react';
import { getPaginationState, ValidatedField } from 'react-jhipster';
import { useLocation, useParams } from 'react-router-dom';
import { Table, Row, Col, Label, Tooltip } from 'reactstrap';
import { getEntities as getGroups } from '../../entities/group/group.reducer';
import { getUsersList as getUserEntities } from '../../entities/ai-chat/ai-chat.reducer';
import { updateUserRole } from '../../entities/user-group/user-group.reducer';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Props {
  cardCols?: string;
  handleViews?: any;
  id?: any;
  dataList?: any;
}
export const UsergroupList = (props: Props) => {
  const dispatch = useAppDispatch();

  const location = useLocation();
  // const navigate = useNavigate();
  // const params = useParams();

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getPaginationState(location, ITEMS_PER_PAGE, 'id'), location.search),
  );

  const { dataList } = props;
  const id = useParams<'id'>().id ? useParams<'id'>().id : dataList?.id;
  const loading = useAppSelector(state => state.userGroup.loading);
  const groupList = useAppSelector(state => state.group.entities);
  const users = useAppSelector(state => state.aIChat.users.data);
  const assistant = useAppSelector(state => state.aiAssitant.entity);

  // const userGroupUpdateSuccess = useAppSelector(state => state.userGroup.updateSuccess);
  // const [selectedValues, setSelectedValues] = useState({});

  const groupsListAdmin = groupList
    .filter(group => group.groupType === 'ADMIN')
    .map(group => ({
      value: group.groupId,
      label: group?.name?.replace('Admin Group', '').replace('User Group', ''),
    }));
  const groupsListUser = groupList
    .filter(group => group.type === 'USER')
    .map(group => ({
      value: group.id,
      label: group.name.replace('User Group', ''),
    }));

  const getAllEntities = () => {
    dispatch(
      getUserEntities({
        page: paginationState.activePage - 1,
        size: 100,
        sort: `${paginationState.sort},${paginationState.order}&asstId=${id}`,
      }),
    );
  };

  // useEffect(() => {
  //   if (userGroupUpdateSuccess == true) {
  //     getAllEntities()
  //   }
  // }, [userGroupUpdateSuccess]);

  const handleChange = (userId, selected) => {
    const accessRole = groupList.find(item => item.groupId == selected);

    if (selected) {
      dispatch(
        updateUserRole({
          isAdmin: accessRole.groupType == 'ADMIN' ? true : false,
          userId: userId,
          aiassistantId: id,
          groupId: selected,
        }),
      );
    }

    // setSelectedValues({id: selected});
  };

  const sortEntities = () => {
    getAllEntities();
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  useEffect(() => {
    dispatch(
      getGroups({
        page: paginationState.activePage - 1,
        size: 100,
        sort: `${paginationState.sort},${paginationState.order}&assistId=${id}`,
      }),
    );
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [location.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    sortEntities();
  };

  let entityFields = ['enabled', 'admin', 'user', 'team'];

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <Row style={{ justifyContent: 'space-between' }} className="pt-3">
      <Col md="4">
        <h3>Team members</h3>
        <p>
          You can manage team member access by assigning roles for <b>{assistant.qdrantCollectionName}</b>.{' '}
          <FontAwesomeIcon icon={faInfoCircle} id="roles-info"></FontAwesomeIcon>
        </p>
        <Tooltip
          {...{
            autohide: true,
            flip: true,
          }}
          isOpen={tooltipOpen}
          target="roles-info"
          toggle={toggle}
        >
          <p>
            Admin: Allows to team member use chat and manage/config as well for <b>{assistant.qdrantCollectionName}</b>
          </p>
          <p>
            User: Allows team member to only use chat for <b>{assistant.qdrantCollectionName}</b>
          </p>
        </Tooltip>
      </Col>

      <Col md="8">
        <div className="theme_table no-border">
          {users ? (
            <Table responsive hover border="0">
              {users.length > 0 && (
                <>
                  <thead className="text-capitalize">
                    <tr>
                      <th>
                        <ValidatedField className="mb-sm-0" name="checkbox1" type="checkbox" />
                      </th>
                      <th>Username</th>
                      <th>User Groups</th>
                    </tr>
                  </thead>
                  <tbody>
                    {users.map((obj, i) => (
                      <tr key={`entity-${i}`} data-cy="entityTable">
                        <td>
                          <ValidatedField name="checkbox2" type="checkbox" />
                        </td>
                        <td>{obj.username}</td>
                        <td>
                          <ValidatedField
                            name="userRole"
                            type="select"
                            // value={obj.assignedGroupType}
                            onChange={e => {
                              handleChange(obj.userId, e.target.value);
                            }}
                          >
                            <option value="">SELECT</option>
                            {groupList.map(item => (
                              <option value={item.groupId} selected={item.groupId === obj.assignedGroupId}>
                                {item.groupType}
                              </option>
                            ))}
                          </ValidatedField>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </>
              )}
            </Table>
          ) : (
            !loading && (
              <div className="alert alert-warning">
                <Label>No User found</Label>
              </div>
            )
          )}
        </div>
      </Col>
    </Row>
  );
};

export default UsergroupList;
