// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.plan-card {
  width: 100%;
  height: 100%;
  perspective: 1000px;
  position: relative;
}
.plan-card .card-face {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  transition: transform 0.6s;
}
.plan-card .card-front {
  transform: rotateY(0deg);
}
.plan-card .card-back {
  transform: rotateY(180deg);
}
.plan-card.is-flipped .card-front {
  transform: rotateY(180deg);
}
.plan-card.is-flipped .card-back {
  transform: rotateY(0deg);
}

.plans-container {
  padding: 20px;
}

.subscription-form .form-group {
  margin-bottom: 15px;
}
.subscription-form .close-button {
  background-color: #6c757d;
  color: white;
  width: 100%;
  margin-top: 10px;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/themes/default/app/modules/subscription-plans/Subscription-plan.scss"],"names":[],"mappings":"AACA;EACE,WAAA;EACA,YAAA;EACA,mBAAA;EACA,kBAAA;AAAF;AAEE;EACE,kBAAA;EACA,WAAA;EACA,YAAA;EACA,2BAAA;EACA,0BAAA;AAAJ;AAGE;EACE,wBAAA;AADJ;AAIE;EACE,0BAAA;AAFJ;AAKE;EACE,0BAAA;AAHJ;AAME;EACE,wBAAA;AAJJ;;AAQA;EACE,aAAA;AALF;;AASE;EACE,mBAAA;AANJ;AAcE;EACE,yBAAA;EACA,YAAA;EACA,WAAA;EACA,gBAAA;AAZJ","sourcesContent":["// PricingPlans.scss\n.plan-card {\n  width: 100%;\n  height: 100%;\n  perspective: 1000px;\n  position: relative;\n\n  .card-face {\n    position: absolute;\n    width: 100%;\n    height: 100%;\n    backface-visibility: hidden;\n    transition: transform 0.6s;\n  }\n\n  .card-front {\n    transform: rotateY(0deg);\n  }\n\n  .card-back {\n    transform: rotateY(180deg);\n  }\n\n  &.is-flipped .card-front {\n    transform: rotateY(180deg);\n  }\n\n  &.is-flipped .card-back {\n    transform: rotateY(0deg);\n  }\n}\n\n.plans-container {\n  padding: 20px;\n}\n\n.subscription-form {\n  .form-group {\n    margin-bottom: 15px;\n  }\n  // .subscription-button {\n  //   background-color: #007bff;\n  //   color: white;\n  //   width: 100%;\n  //   margin-top: 10px;\n  // }\n  .close-button {\n    background-color: #6c757d;\n    color: white;\n    width: 100%;\n    margin-top: 10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
