import { createSlice } from '@reduxjs/toolkit';

export const chatDashboard = createSlice({
  name: 'chatDashboard',
  initialState: {
    showTemplates: true,
    userOldConversationId: null,
    userOldConversation: '',
    userPromptFullText: '',
    isOpenFavourites: false,
    isOpenChatList: true,
    showExtraPrompts: false,
    selectedPrompt: '',
    showSubscriptionAlert: false,
    mobileViewNewChatButtonClicked: '',
  },
  reducers: {
    setShowTemplates: (state, action) => {
      state.showTemplates = action.payload;
    },
    setUserOldConversationId: (state, action) => {
      state.userOldConversationId = action.payload;
    },
    setUserOldConversation: (state, action) => {
      state.userOldConversation = action.payload;
    },
    setIsOpenFavourites: (state, action) => {
      state.isOpenFavourites = action.payload;
    },
    setIsOpenChatList: (state, action) => {
      state.isOpenChatList = action.payload;
    },
    setUserPromptFullText: (state, action) => {
      state.userPromptFullText = action.payload;
    },
    setShowExtraPrompts: (state, action) => {
      state.showExtraPrompts = action.payload;
    },
    setSelectedPrompt: (state, action) => {
      state.selectedPrompt = action.payload;
    },
    setShowSubscriptionAlert: (state, action) => {
      state.showSubscriptionAlert = action.payload;
    },
    setMobileViewNewChatButtonClicked: (state, action) => {
      state.mobileViewNewChatButtonClicked = action.payload;
    },
  },
});

export const {
  setShowTemplates,
  setUserOldConversationId,
  setUserOldConversation,
  setIsOpenFavourites,
  setIsOpenChatList,
  setUserPromptFullText,
  setShowExtraPrompts,
  setSelectedPrompt,
  setShowSubscriptionAlert,
  setMobileViewNewChatButtonClicked,
} = chatDashboard.actions;

export default chatDashboard.reducer;
