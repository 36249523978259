import { IUser } from 'app/shared/model/user.model';
import { IAiAssitant } from 'app/shared/model/ai-assitant.model';
import { IGroup } from 'app/shared/model/group.model';

export interface IUserGroup {
  id?: number;
  enabled?: boolean;
  admin?: boolean;
  user?: IUser | null;
  aiAssitant?: IAiAssitant | null;
  group?: IGroup | null;
}

export const defaultValue: Readonly<IUserGroup> = {
  enabled: false,
  admin: false,
};
