import { getEntity, updateEntity, userWaitList } from '../../entities/wait-list/wait-list.reducer';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Card } from 'reactstrap';
import { ValidatedField, ValidatedForm } from 'react-jhipster';
import { useAppDispatch, useAppSelector } from '../../../../../app/config/store';
import { CircleEditIcon, CreatorsIcon, DollarICon } from '../../shared/icon-svg/icon';
import ThankYouModal from './ThankYouModal';
import * as KnolliAnalytics from '../../shared/services/analytics.tsx';

interface Props {
  id?: number;
  handleViews?: any;
}

export const WaitlistFormView = (props: Props) => {
  const dispatch = useAppDispatch();

  let id;
  if (props.id) {
    id = props.id;
  } else {
    id = useParams<'id'>()?.id;
  }
  const isNew = id === undefined;
  // const searchParams = new URLSearchParams(location.search);
  const waitList = useAppSelector(state => state.waitList.entity);
  const loading = useAppSelector(state => state.waitList.loading);
  const updateSuccess = useAppSelector(state => state.waitList.updateSuccess);
  const updating = useAppSelector(state => state.waitList.updating);
  const [thankYouModal, setThankYouModal] = useState(false);
  const [email, setEmail] = useState('');

  useEffect(() => {
    KnolliAnalytics.analyticsTrack('Waitlist Page');
  }, []);

  useEffect(() => {
    if (!isNew) {
      dispatch(getEntity(id));
    }
  }, [id]);

  const handleModal = () => {
    setThankYouModal(!thankYouModal);
  };

  useEffect(() => {
    if (updateSuccess) {
      handleModal();
    }
  }, [updateSuccess]);

  const saveEntity = async values => {
    let entity = {
      ...values,
    };

    if (isNew) {
      await dispatch(userWaitList(entity));
    } else {
      entity = { ...entity, id: waitList.id };
      await dispatch(updateEntity(entity));
    }
    KnolliAnalytics.analyticsTrack('Waitlist Page', { email: entity.email });
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          ...waitList,
        };

  let entityFields = ['email'];

  return (
    <>
      <div className="main-wrapper">
        <div className="banner-content-wrapper px-3">
          <div className="main-content-block mb-5">
            <h1 className="fw-500 mt-5">
              {/* {isNew ? 'Create' : 'Edit'}  */}
              Where knowledge comes to life
            </h1>
            <p className="text-center mt-0 mb-1">
              Knolli.ai is almost here! Be among the first to transform your knowledge into interactive, monetizable conversations.
            </p>
            <div>
              {loading ? (
                <p>Loading...</p>
              ) : (
                <ValidatedForm className="waitlist-cta" defaultValues={defaultValues()} onSubmit={saveEntity}>
                  {entityFields.map(entityName => (
                    <ValidatedField
                      id={entityName}
                      name={entityName}
                      key={entityName}
                      placeholder={`Your ${entityName}`}
                      data-cy={entityName}
                      type="text"
                      required
                      onChange={e => setEmail(e.target.value)}
                    />
                  ))}
                  <Button color="theme" id="save-entity" type="submit" disabled={updating}>
                    Join Waitlist
                  </Button>
                </ValidatedForm>
              )}
            </div>
            <div className="sm-text text-center">
              Spots are limited for early access. Don't miss your chance to shape the future of knowledge sharing!
            </div>

            <div className="customer-count">
              <div className="user-icon">
                <img src="../../content/images/user-img.png" alt="user-img1" />
                <img src="../../content/images/user-img.png" alt="user-img1" />
                <img src="../../content/images/user-img.png" alt="user-img1" />
              </div>
              <p className="m-0">1000+ already joined</p>
            </div>
          </div>
          <div className="bottom-text-content mt-5 mb-5">
            <span className="fw-bold">Your knowledge deserves a bigger audience!</span> Knolli.ai empowers creators like you to share
            expertise, engage audiences, and monetize insights in an innovative, AI-driven way. Join the waitlist today and be the first to
            turn your expertise into something extraordinary.
          </div>
        </div>

        <div className="join-waitlist-section">
          <Card className="bg-white py-5" key="card">
            <div className="cardtitle mt-3 mt-md-4 mt-lg-5 mb-4">Why Join the Waitlist?</div>

            <div className="row row-cols-1 row-cols-md-3 custom-width mx-auto mt-3 mt-md-4 mt-lg-5">
              <div className="col mb-3">
                <div className="card-block">
                  <CircleEditIcon />
                  <p className="mb-0">Get early access to Knolli and its exclusive knowledge-creation tools</p>
                </div>
              </div>
              <div className="col mb-3">
                <div className="card-block">
                  <DollarICon />
                  <p className="mb-0">Unlock the ability to monetize your knowledge and expertise from day one</p>
                </div>
              </div>
              <div className="col mb-3">
                <div className="card-block">
                  <CreatorsIcon />
                  <p className="mb-0">Be a part of a pioneering community of knowledge creators.</p>
                </div>
              </div>
            </div>
          </Card>

          <div className="footer-text">
            Your <span>expertise</span>. Your <span>audience</span>. Your way. Coming soon to <span>Knolli</span>
          </div>
        </div>
      </div>
      {thankYouModal && <ThankYouModal handleModal={handleModal} email={email} />}
    </>
  );
};

export default WaitlistFormView;
