import dayjs from 'dayjs';
import { IUser } from 'app/shared/model/user.model';
import { IAIChat } from 'app/shared/model/ai-chat.model';
import { IUserPrompt } from 'app/shared/model/user-prompt.model';
import { IAiAssitant } from 'app/shared/model/ai-assitant.model';

export interface IGeneratedMessage {
  id?: number;
  userRating?: number | null;
  formattedMessage?: string | null;
  rawMessage?: string | null;
  createdDate?: dayjs.Dayjs | null;
  updatedDate?: dayjs.Dayjs | null;
  deletedDate?: dayjs.Dayjs | null;
  tokenCount?: number | null;
  user?: IUser | null;
  aIChat?: IAIChat | null;
  userPrompt?: IUserPrompt | null;
  aiAssitant?: IAiAssitant | null;
}

export const defaultValue: Readonly<IGeneratedMessage> = {};
