import dayjs from 'dayjs';

export interface ISemanticSearchEntity {
  id?: number;
  entityId?: number | null;
  pointId?: string | null;
  entityName?: string | null;
  createdAt?: dayjs.Dayjs | null;
  updatedAt?: dayjs.Dayjs | null;
}

export const defaultValue: Readonly<ISemanticSearchEntity> = {};
