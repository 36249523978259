import './team-member-management.scss';
import UsergroupList from './user-group-listing';
import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import UserGroupForm from './user-group-update';
import { getPaginationState } from 'react-jhipster';
import { ITEMS_PER_PAGE } from '../../../../../app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from '../../../../../app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from '../../../../../app/config/store';
import Sidebar from '../sidebar/sidebar';

interface Props {
  handleViews?: any;
  id?: any;
}

export const TeamMembersManagement = (props: Props) => {
  const [showViews, setShowViews] = useState('');
  const [id, setId] = useState(undefined);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const params = useParams<'id'>().id;
  const userGroupList = useAppSelector(state => state.userGroup.entities);
  // const productEntity = useAppSelector(state => state.product.entity);

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getPaginationState(location, ITEMS_PER_PAGE, 'id'), location.search),
  );

  const [userIdArray, setUserIdArray] = useState([]);
  useEffect(() => {
    if (userGroupList && userGroupList.length > 0) {
      userGroupList.map(item => !userIdArray.includes(item.user?.id) && userIdArray.push(item.user?.id));
    }
  }, [userGroupList]);

  const handleViews = (type, id) => {
    setShowViews(type);
    setId(id);
  };

  return (
    <div className="dashboard-app-p">
      <Sidebar />
      <div className={'is_open p-3'}>
        <h2
          id="ai-assitant-heading"
          data-cy="AiAssitantHeading"
          className="main-title d-flex justify-content-between gap-3 align-items-center"
        >
          Team Management
        </h2>
        <p>Manage your team members and their account permissions here.</p>
        <div className="card-wrapper pt-4 p-3">
          <div className="border-bottom py-3">
            <UserGroupForm idArray={userIdArray} />
          </div>
          <div className="py-3">
            <UsergroupList handleViews={handleViews} dataList={userGroupList} />
          </div>
          <span id="custom-components" />
        </div>
      </div>
    </div>
  );
};

export default TeamMembersManagement;
